import React from 'react';
import { getViewPartner } from '../../action/partner/getViewPartners'
import { getViewPartnerSearch } from '../../action/partner/getViewPartnerSearch'
import ViewPartnerList from './viewedPartnerList';
import { Link } from 'react-router-dom';
import Loader from '../loader/loader';
import { connect } from "react-redux";
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { GTMUserIdAndLoginState } from '../../utils/GtmDatalayer';
import { PropTypes } from 'prop-types';

export class ViewedPartner extends React.Component {
    constructor(props) {
        super(props);
        this.searchCall = React.createRef();
        this.state = {
            viewedPartnerDataList: null,
            loading: false,
            value: '',
            searchValue: '',
            showMessage: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.getupdaterecentviewpartner = this.getupdaterecentviewpartner.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    goBack(e) {
       analyticsClickEvent('recently_viewed_back','')
        this.props.history.push("/shop");
    }

    componentDidMount() {
        this.initialLoad();

        window.dataLayer = window.dataLayer || {};
        GTMUserIdAndLoginState();

    }

    initialLoad = async () => {
        this.setState({ viewedPartnerDataList: null });
        let viewedPartnerData = [];
        this.setState({ showMessage: false });
        this.setState({ loading: true });
        const objTemp={email:localStorage.getItem('email')}
        const result = await this.props.getViewPartner(objTemp);
        if (Array.isArray(result.data.data) && result.data.data.length !== 0) {
        await Promise.all(result.data.data.map( async(response) => {
           const dataObj={
                partnerId: response.account_id,
                partnerName: response.account_Name,
                partnerIndustry: response.account_Industry,
                partnerLogo: response.account_flxLogo__c,
                partnerCoverImage: response.account_Cover_Image__c,
                partnerWebSite: response.account_Website,
                partnerFavId: response.fav_id
           }
           viewedPartnerData.push(dataObj);
        
       }));
    }


        this.timer = setTimeout(() => {
            this.setState({ loading: false });
        }, 0
        );
        console.log(this.timer);
        if (result.data.message === "No viewed partner found this user") {
            this.setState({ showMessage: true });
        }

        this.setState({ viewedPartnerDataList: viewedPartnerData });

    }



    handleChange = async (event) => {
        let value = '';

        if (this.state.searchValue !== '') {
            value = this.state.searchValue;
        }

        if (event !== undefined) {

            const target = event.target;
            value = target.value;
            const name = target.name;
            this.setState({ [name]: value });

        }
        const obj = {
            email: localStorage.getItem('email'),
            partner_name: value
        }
        let viewedPartnerData = [];

        const result = await this.props.getViewPartnerSearch(obj);
        this.setState({ viewedPartnerDataList: null });

        
        if (Array.isArray(result.data.data) && result.data.data.length !== 0) {
        await Promise.all(result.data.data.map( async(responseData) => {
           const dataObj={
                partnerId: responseData.account_id,
                partnerName: responseData.account_Name,
                partnerIndustry: responseData.account_Industry,
                partnerLogo: responseData.account_flxLogo__c,
                partnerCoverImage: responseData.account_Cover_Image__c,
                partnerWebSite: responseData.account_Website,
                partnerFavId: responseData.fav_id
           }
           viewedPartnerData.push(dataObj);
        }));
         }
        this.setState({ viewedPartnerDataList: viewedPartnerData, loading: false });
        if (this.searchCall?.current) {
            this.searchCall.current.viewedPartnerChange(this.state.viewedPartnerDataList);
        }
        this.getFilterValues();

    }



    getupdaterecentviewpartner = async () => {
        this.getFilterValues();
    }

    getFilterValues(){
        setTimeout(async() => {
        let unfavoruitViewedPartnerData = [];
        this.setState({ showMessage: false });
        let value = '';

        if (this.state.searchValue !== '') {
            value = this.state.searchValue;
        }

        const objEmail = {
            email: localStorage.getItem('email'),
            partner_name: value
        }
        const apiresult = await this.props.getViewPartnerSearch(objEmail);
        if(apiresult.data.statusCode === 429){
            this.props.history.push('/viewed-partner');
        }
        
        if (Array.isArray(apiresult.data.data) && apiresult.data.data.length !== 0) {
        await Promise.all(apiresult.data.data.map( async(response) => {
           const responsedata={
                partnerId: response.account_id,
                partnerName: response.account_Name,
                partnerIndustry: response.account_Industry,
                partnerLogo: response.account_flxLogo__c,
                partnerCoverImage: response.account_Cover_Image__c,
                partnerWebSite: response.account_Website,
                partnerFavId: response.fav_id
           }
           unfavoruitViewedPartnerData.push(responsedata);
        }));
         }
        this.setState({ viewedPartnerDataList: unfavoruitViewedPartnerData, loading: false });
        if (this.searchCall?.current) {
            this.searchCall.current.viewedPartnerChange(this.state.viewedPartnerDataList);
        }
    },200);
    }

    
    favoritePartnerIconClickEvent(e){
        analyticsClickEvent('favorite_partner_icon','');
        if(localStorage.getItem('fromWhere')==='skip'){
            e.preventDefault();
        }
    }

    viewedPartnerIconClickEvent(e){
        analyticsClickEvent('viewed_partner_icon',''); 
        if(localStorage.getItem('fromWhere')==='skip'){
            e.preventDefault();
        }
    }

    floatingQuoteIconClickEventForViewed(){
        localStorage.setItem('prevRoute',window.location.pathname)
        analyticsClickEvent("common_quote_calculator", "")
    }


    render() {

        return (
            <div className="wrapper">
                <Loader loaderStatus={this.state.loading} />
                <section className="main">
                    <div className="search-fav-section">
                        <div className="container">
                            <div className="d-flex align-items-center top-section-partner">
                                <div className="left-iteQerd">
                                    <div className="back-btn-item">
                                        <a className="back-btnQr" onKeyDown={(e) => { e.preventDefault(); }} onClick={(e) => this.goBack(e)}><img src="images/back.svg" alt="<-" /> Back </a>
                                    </div>
                                </div>
                                <div className="right-iteQerd">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <form className="search-iconbanner">
                                                <div className="form-group m-0">
                                                    <input className="form-control" name="searchValue" value={this.state.searchValue} placeholder="Search" onChange={this.handleChange} type="text" onBlur={(e) => analyticsClickEvent('viewed_partner_search',this.state.searchValue)} onClick={(e) => analyticsClickEvent('viewed_partner_search','')}/>
                                                    <span className="search-icon"><img src="images/search-Icon-white.svg" alt="search" /></span>
                                                </div>
                                            </form>
                                        </div>
                                        {localStorage.getItem('fromWhere')!='skip' &&
                                        <div className="fav-eye-item">
                                            <div className="icon-itemQer">
                                                <Link to="/favorite-partner"  onClick={this.favoritePartnerIconClickEvent}>
                                                    <img src="images/fav-icon.svg" alt="fav-icon" 
                                                    onKeyDown={(e) => { e.preventDefault(); }}
                                                    onClick={() => {this.setState({ redirect: true }) }} className="default" />
                                                </Link>
                                            </div>
                                            <div className="icon-itemQer active">
                                                <Link to="/viewed-partner" onClick={this.viewedPartnerIconClickEvent}>
                                                    <img src="images/active-view.svg" alt="eye-icon" 
                                                    onKeyDown={(e) => { e.preventDefault(); }}
                                                    onClick={() => { this.setState({ redirect: true }) }} className="active" />
                                                </Link>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">

                        <div className="image-gallery">
                            <h3 className="partner-page-title">Recently Viewed</h3>
                            <div className="featured-partners-list row">


                                {this.state.viewedPartnerDataList &&
                                    <ViewPartnerList itemList={this.state.viewedPartnerDataList} ref={this.searchCall} handleViewChange={this.handleChange} updaterecentviewpartner={this.getupdaterecentviewpartner}/>

                                }


                            </div>
                        </div>
                    </div>
                    <div className="floating-pageicon">
                        <div className="calculator-icon">
                            <Link
                            to="/quote-calculator"
                            onClick={this.floatingQuoteIconClickEventForViewed}
                            >
                            <span>
                                <img src="images/calculator.svg" alt="icon" />
                            </span>
                            </Link>
                        </div>
                    </div>
                </section>
                {this.state.showMessage === true &&
                    <section className="section">
                        <div className="container">
                            <h3 className="partner-page-title">You are yet to view a partner, select a partner to get Humming!</h3>
                        </div>
                    </section>

                }


            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        viewedPartnerStore: state.dataStore.viewedPartnerStore,
        viewedPartnerSearchStore: state.dataStore.viewedPartnerSearchStore,
        storePartnerImage: state.dataStore.storePartnerImage,
    };
};


export const mapDispatchToProps = (dispatch) => {
    return {
        getViewPartner: (formData) => {
            return dispatch(getViewPartner(formData));
        },
        getViewPartnerSearch: (formData) => {
            return dispatch(getViewPartnerSearch(formData));
        },
        storePartnerImagefunction:(formData)=>{
            return dispatch({
                type: 'STOREPARTNERIMAGE',
                value: formData
            });
        }
    }
}

ViewedPartner.propTypes = {
    history: PropTypes.object,
    getViewPartner: PropTypes.func,
    getViewPartnerSearch: PropTypes.func,

}

export default connect(mapStateToProps, mapDispatchToProps)(ViewedPartner);
