import axios from 'axios';
import config from '../config';
import {sessionID} from '../../utils/handlingSession';
import { handleStatusCode } from '../../utils/handleStatusCode';
import Cookies from 'universal-cookie';

export const userLogs = async (api_endpoint,formData,err, log_description='',status_code='') => {
      const cookie = new Cookies();
     let url = config.hummBackendAppURL+'user-logs/logs';
     let date =  new Date().toJSON().replace("T",' ').replace("Z",'+00');
     let user = (localStorage.getItem('fromWhere') === 'skip') ? 'skip': 'login';

     let logDescription=log_description;
     let statusCode=status_code;
     let methodName="";
     let errorMessage="";
     if(err.response){
         logDescription=err.response.data.message;
         statusCode=err.response.data.statusCode;
         methodName=err.response.config.method;
         errorMessage=err.message;
     }

     let critical=false;

     if(statusCode=="500" || statusCode=="503"){
        critical=true;
     }

     if(formData !== undefined){
      if(Object.keys(formData).length == 0 && logDescription === 'Retailer ID not received from branch link'){
         critical=false;
      }
     }

     let apiPointPath=api_endpoint.split('/').pop();

     if(apiPointPath=='verify_otp' ||  apiPointPath=='validate_phonenumber' || apiPointPath == 'validate'){
        critical=false;
     }

     formData = setFormDataOfApiCall(formData, apiPointPath, statusCode)

     if(apiPointPath=='get_mitek_document_response'){
        formData='';
     }

     critical = setCriticalParamForAPIEndpoints(apiPointPath, statusCode, err, critical);

     let obj = {
        "userId":sessionID().session_id, 
        "log_description":  logDescription,
        "logtypeLogTypeId": "", 
        "status_code": statusCode,
        "critical":   critical,
        "class_name": "",  
        "module_name": window.location.href,
        "method_name": methodName, 
        "line_number": "", 
        "device_id": "",
        "session_id": sessionID().session_id, 
        "login_type": user,
        "ip_address": "", 
        "created_date": date,
        "build_number": "",
        "device_type": "web",            
        "api_endpoint": api_endpoint,
        "error_message":errorMessage,
        "request_payload": formData,
        "customer_email": localStorage.getItem('email'),
        "application_id": localStorage.getItem('applicationId') ? localStorage.getItem('applicationId') :cookie.get('applicationId'),
        "user_tracking_id": localStorage.getItem('user_tracking_id')
    }
    return await axios.post(url,obj,{headers:sessionID()}).then(res => {
        return res
    })
    .catch(err => {
        return err.response
    })


};

const setCriticalParamForAPIEndpoints= (apiPointPath, statusCode, errorResponse, critical) => {

   if(apiPointPath == 'send_mobile_otp' || apiPointPath == 'verify_mobile_otp'
   || apiPointPath === 'verify_email_otp' || apiPointPath === 'send_email_otp' 
   || apiPointPath === 'generate_session' || apiPointPath === 'check_user_existence' 
   || apiPointPath === 'check_user_app_eligiblity'|| apiPointPath === 'submit_application'
   || apiPointPath === 'get_all_application_questions'){
      let resultErrorMessage = errorResponse.response.data.message.toLowerCase();
      if(resultErrorMessage.includes("something went wrong") || resultErrorMessage.includes("required field")) {
      critical = true
      }
      else if(handleStatusCode(statusCode)) {
         critical = false;
      }
   }

   return critical;
}

const setFormDataOfApiCall = (formData, apiPointPath, statusCode) => {
   if(apiPointPath === 'password-change' && statusCode === 400) {
      if("new_password" in formData){
         let resultFormData = formData;
         resultFormData.new_password = '';
         formData = resultFormData;
      }
   }

   return formData
}