
import axios from 'axios';
import { userLogs } from '../logs/userLogs';
import {handlingSession,sessionID} from './../../utils/handlingSession'

export const QuoteCalculatorAPI = async (formData) => {
    let url = process.env.REACT_APP_BACKEND+'pca/retailers_list'
    
   return axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async error => {
        await userLogs(url,formData,error);
        if(error.response){
        handlingSession(error.response.status);
        }
        return error.response
    })


};