import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const getAvatar = async (formData) => {
     let url = config.hummBackendAppURL+'user/getUser';
   return axios.post(url,formData ,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
      await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};

export const getApplicationImage = async (formData) => {
  let url = config.hummBackendAppURL+'application/get_Application_image';
return axios.post(url,formData ,{headers:sessionID('secured')}).then(res => {
     return res;
 })
 .catch(async err => {
  await userLogs(url,formData,err);
     return err.response
 })


};