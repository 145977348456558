import React from "react";
import {manualLogin,emailCheck} from "../../../action/auth/login";
import { Link,Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import {hasPasswordEmpty} from '../../validation/passwordValidation';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import parse from 'html-react-parser';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { getApplicationImage, getAvatar } from "../../../action/avatar/getAvatar";
import getApplicationBannerImg from "../../../utils/getApplicationBannerImg";
import { GTMFieldInteraction, GTMFieldSubmitErrors, GTMFieldValidChange, GTMFieldErrorChange } from "../../../utils/GtmDatalayer";
import { frontEndLogs } from "../../../action/logs/frontEndLogs";
import { extractEmail } from "../../../utils/extractEmail";
import SocialMedia from '../register/socialMedia';
import { PropTypes } from 'prop-types';
import Loader from "../../loader/loader";

export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          email:'',  
          password:'',
          disable:'',
          serverError:'',
          loginServerError: '',
          profilePicture:props.profilePicture,
          errors:{
            emailError:'',
            passwordError:'',
          },
          emailSuccess:false,
          inputType:false,
          isLogin:false,
          redirect:false,
          hasEmailMessage:'',
          customPath:'/application',
          isEnabled:true,
          loginMessage:'',
      forgotPasswordservermsg: '',
          shopPathname: '',
          loading:false
          
        };
        
          this.handleChange = this.handleChange.bind(this);
          this.inputChangeHandler = this.inputChangeHandler.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
          this.handleBlur =this.handleBlur.bind(this);
          this.handleFromHistory =this.handleFromHistory.bind(this);
          
      }

      componentDidMount(){
        let resultWebview = this.searchAppleCode();
        console.log('loadeer_resultWebview',resultWebview);
    if(resultWebview){
      this.setState({loading:true});
      setTimeout(() =>{
        this.setState({loading:false});
        console.log('setTimeout',this.state.loading);
      },2000)
      
    }

        // analyticsScreenViewEvent()
           window.addEventListener("popstate", this.handleFromHistory);

          localStorage.clear();
          if(this.props.customPathname != null && this.props.customPathname !== ''){
            this.setState({shopPathname: this.props.customPathname})
          }

      }

      appleResponseRecivedLoader =(responseStatus)=>{
        if(responseStatus === true){
          this.setState({loading:true});
        }else{
          this.setState({loading:false});
        }
      }

      searchAppleCode = ()=> {
        let appurl = window.location.href
        appurl = appurl.replace("?","&")
        let searchParams = new URLSearchParams(appurl);
        let resultWebview = searchParams.get("code");
        if(resultWebview){
          return resultWebview;
        }
      }

     analyticseveeent = (e) => {
      let text = e.target.innerText.replace(' ','_');
      analyticsClickEvent(text,'')
     }

    handleFromHistory(){
      
      if(window.$('#loginModel').hasClass('show') === false){
        window.$('#loginModel').modal('hide');
        window.$('.modal-backdrop').removeClass('show');
        window.$('body').removeClass('modal-open');
        window.$('.modal-backdrop').remove();
      }
    }

    blurEvent = (event) =>{
      const name = event.target.name;
      switch(name){
        case 'email':
          if(this.state.errors.emailError !== '' && this.state.errors.emailError !== null){
            GTMFieldErrorChange('Email');
          }else {
            GTMFieldValidChange('Email');
          }
          break;
        case 'password':
          if(this.state.errors.passwordError !== '' && this.state.errors.passwordError !== null){
            GTMFieldErrorChange('Password');
          }else {
            GTMFieldValidChange('Password');
          }
          break;
      }
    }

  validateEmail(email) {
    const allowedSpecialCharecters = "[!#$%&'*+/=?^._`{|}~-]";
    const notUsedConsecutiveSpecialChars = "[!#$%&'*+/=?^`{|}~]";
    const consecutiveSpecialCharsRegex =
      `(?!.*${allowedSpecialCharecters}${notUsedConsecutiveSpecialChars})` +
      `(?!.*${notUsedConsecutiveSpecialChars}${allowedSpecialCharecters})` +
      `(?!.*[.]{2})`;
    const localPartRegex = "[a-zA-Z0-9](?:[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*[a-zA-Z0-9-_])?";
    const domainPartRegex = "@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$";
    const emailRegex = new RegExp("^" + consecutiveSpecialCharsRegex + localPartRegex + domainPartRegex + "$");
    return emailRegex.test(email);
  };
   

   handleChange(event) {
    this.setState({isEnabled:false})
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value});
    const commonState= this.state;
  // analyticsTypeEvent(name.toLocaleLowerCase())
    if(commonState.serverError){
      
      this.setState({ serverError:null});
    }
     let errors = commonState.errors;
    switch(name){
      case 'email': 
      if (
        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@[a-z0-9-]+(\.[a-z]{2,})+$/i.test(
          value
        ) && this.validateEmail(value)
      ) {
        errors.emailError=null;
      }else if (value.trim() === "") {
         errors.emailError = "Email is required";
      }else{
         errors.emailError= "Please enter a valid email";
      }
      break;
      case 'password':
      {
          let result = hasPasswordEmpty(value);
          errors.passwordError = result || null;
      }
      break;

      default:
        errors.passwordError=null;
        errors.emailError=null;

    }

    this.setState({errors, [name]: value, serverError: ''})

    if(commonState.emailSuccess){
      this.setState({emailError:null});
    }
    if(errors.emailError){
      this.setState({
        emailSuccess:false
      })
      this.setState({isEnabled:true})
    }
setTimeout(() => {
  this.handleBlur(event.target.value)
  .catch(error => console.error(error));
}, 2000);
    
  }


  async handleBlur(email) {
    const emailValue= email;
  if (/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@[a-z0-9-]+(\.[a-z]{2,})+$/i.test(emailValue) && this.validateEmail(emailValue)) {
      const obj={'email':emailValue}
      // setTimeout( async() => {
      const result = await emailCheck(obj);
      console.log('email',result);
      await this.setState({
      errors:{...this.state.errors,emailError:null}
      });
      await this.handleEmailCheck(result)
  }
    }

  async handleEmailCheck (result) {
    if (result && result.status === 400) {
      switch (result.data.message) {
        case "This account does not exist with humm":
        case "User need to activate":
        case "User not activated":
          {
              const Emailerrors = { ...this.state.errors, emailError: result.data.message };
              if (this.state.serverError == null || this.state.serverError == '') {
                await this.setState({
                  errors: Emailerrors,
                  emailSuccess: false
                });
              }
         }
          break;
        case "User already exist":
          {
              const errors = { ...this.state.errors, emailError: null };
              await this.setState({
                errors: errors,
                emailSuccess: true,
                isEnabled: false,
                customPath: "/welcome",
              });
         }
          break;
        case "This is social media login. Click ok to reset the password":
          this.setState({ hasEmailMessage: 'This email has previously been used for a social media login, add a password by resetting' });
          this.setState({ customPath: "/forgot-password" });
          window.$("#loginModel").modal("show");
      }

    }
  }


  redirectForgetPasswordScreen=()=>{
    window.$('#loginModel').modal('hide');
    this.setState({redirect: true});
    this.setState({domainMessage: ''});
  }

  initialValidation(email,password){
    let errors= this.state.errors;
    if(email.trim()===''){
      errors.emailError='Email is required';
      this.setState({errors});
    }

    let data=hasPasswordEmpty(password)
    errors.passwordError = data || null;
    this.setState({errors});
  
  }

  getApplicationBannerImage_forLogin = async() => {
    if(localStorage.getItem('fromWhere') !='skip'){
        const ResultOfApplicationImage = await getApplicationImage();
        if (ResultOfApplicationImage && ResultOfApplicationImage.status === 201 && Array.isArray(ResultOfApplicationImage.data.image) && ResultOfApplicationImage.data.image.length !== 0 && ResultOfApplicationImage.data.image[1] != undefined) {
                let appImg = ResultOfApplicationImage.data.image[1];
                localStorage.setItem('applicationImage', appImg);                                   
        }
        else {
            localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
        }
    }
  }

  getProfilePic_forLogin = async() => {
    const emailObj={
        email:localStorage.getItem('email')
    }

    const image_Result = await getAvatar(emailObj);
    if(image_Result && image_Result.data.statusCode===200){
      localStorage.setItem('UserName',image_Result.data.data.first_name+' '+image_Result.data.data.last_name);
       if(image_Result.data.data.image_path!==undefined){
          let userPicture ='data:image/jpeg;base64,'+image_Result.data.data.image_path
          this.props.setProfilePic(userPicture);
      }
       else{
          let userPicture= localStorage.getItem("profilePicture");
         if(userPicture!=null){
          this.props.setProfilePic(userPicture);
          }
          if(localStorage.getItem('fromWhere')==='skip'){
              let fallbackImage ='images/profile-avatar.png';

              this.props.setProfilePic(fallbackImage);
          }
       }

    }
  }

  handleFocusForLogin = (e) =>{
    if(e.target.name == 'email'){
        GTMFieldInteraction('Email');
    }
    if(e.target.name == 'password'){
        GTMFieldInteraction('Password');
    }
  }

  handleClientLoginErrors = (emailError, passwordError) => {
    if(emailError !== '' && passwordError !== '' && emailError !== null && passwordError !== null){
      let loginErrors = [emailError, passwordError];
      GTMFieldSubmitErrors('Login', 'client', loginErrors);
      return false;
    }

    if(emailError !== '' && emailError !== null){
      GTMFieldSubmitErrors('Login', 'client', emailError);
      return false;
    }
    if(passwordError !== '' && passwordError !== null){
      GTMFieldSubmitErrors('Login', 'client', passwordError);
    }
  }

  async handleSubmit(event) {
    let text = event.target.defaultValue.replace(' ','');
    analyticsClickEvent(text.toLocaleLowerCase(),'')
    event.preventDefault();
    const obj={
               'email':this.state.email,
               'password':this.state.password,
               'Application_Channel': 'Web'
              }
    this.initialValidation(obj.email,obj.password);
  
    if(this.state.errors.passwordError || this.state.errors.emailError){
      // send errors of email and password field to the GTM when the errors of state is not empty
      this.handleClientLoginErrors(this.state.errors.emailError, this.state.errors.passwordError);
      return false;
    }
  
    this.setState({disable:'disabled'});
    const result = await this.props.manualLogin(obj);

    this.handleLoginAPIResult(result);
 
    }

    redirectionToApplicationIfRetailerIdExists = () => {
      const cookies = new Cookies();
      if(cookies.get('retailerId') != undefined && cookies.get('retailerId') !== "undefined" && cookies.get('retailerId') !== null){
        this.getApplicationBannerImage_forLogin()
        .catch(error => console.error(error));

        this.getProfilePic_forLogin()
        .catch(error => console.error(error));

        this.setState({ customPath: '/application' })
      }
    }

    handleLoginAPIResult = (resultData) => {
      const result = resultData;

      if (result !== undefined) {
        if (result.status === 200) {
          const userTrackingId = uuidv4();
          localStorage.setItem('user_tracking_id', userTrackingId);

          analyticsClickEvent('login', 'manual_login');
          localStorage.setItem('token', result.data.data.access_token);
          localStorage.setItem('email', result.data.data.user_details.email);
          localStorage.setItem('UserName', result.data.data.user_details.username);
          localStorage.setItem('sessionId', result.data.data.session_id);
          localStorage.setItem('csrfToken', result.data.data.csrf_token);

          localStorage.setItem('emailConsent', result?.data?.data?.user_details?.marketing_consent?.email_marketing_consent);
          localStorage.setItem('smsConsent', result?.data?.data?.user_details?.marketing_consent?.sms_marketing_consent);
          localStorage.setItem('mobileConsent', result?.data?.data?.user_details?.marketing_consent?.mobile_app_marketing_consent);

          this.setState({ customPath: '/welcome' })
  
          if(result.data.data.user_details.userid !== '' && result.data.data.user_details.userid != null && 
          result.data.data.user_details.userid != undefined){
            localStorage.setItem('userID', result.data.data.user_details.userid);
          }

          // redirect to application page if retailer id exists from after the user has logged in
          this.redirectionToApplicationIfRetailerIdExists();
          
          if(this.state.shopPathname != ''){
            this.setState({customPath: this.state.shopPathname})
            getApplicationBannerImg()
            .catch(error => console.error(error));
          }
          this.props.setCustomPath('');
          this.setState({ redirect: true });
        } else {
          setTimeout(() => {
            const Emailerrors = { ...this.state.errors, emailError: '' };
            this.setState({
              errors: Emailerrors
            });
  
          }, 1000);
          let resultMessage = result.data.message

          if(this.userTypeCheckForLoginResponse(result.data.data)) {
            resultMessage = this.convertMessage(resultMessage);
            this.setState({ loginServerError: resultMessage });
            window.$("#serverErrorShowModel").modal("show");
            return false;
          }

          this.setState({serverError: resultMessage});
  
          // Pass the server errors to the method along with current page name
          GTMFieldSubmitErrors('Login','server', result.data.message);
  
          this.setState({ disable: '' })
        }
      }
    }

    userTypeCheckForLoginResponse = (resultResponse) => {
      return (resultResponse.userLoginType !== null && resultResponse.userLoginType !== '' && resultResponse.userLoginType === 6);
    }

    convertMessage = (resultMessage) => {
      if (extractEmail(resultMessage) != null) {
        let newtext = '';
        let extractValue = extractEmail(resultMessage);
        extractValue.map((src) => {
          let anchorTag = "<a onClick='analyticsClickEvent('email_link','')' class='apply-important-info-link' target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
          newtext = resultMessage.replace(src, anchorTag);
          resultMessage = newtext;

        })
      }

      return resultMessage;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.profilePicture !== nextProps.profilePicture) {
        return {
          profilePicture: nextProps.profilePicture
        };
      }
      
      return null;
  }

    inputChangeHandler(event){
      analyticsClickEvent('login_password_view_icon','')
        this.setState({
          inputType: !this.state.inputType
          })   
    }

    applyNowFlow = () => {
      localStorage.setItem('fromWhere', 'apply_now');
      if(!localStorage.getItem('user_tracking_id')) {
        const trackingId = uuidv4();
        localStorage.setItem('user_tracking_id', trackingId);
      }

      frontEndLogs('apply_now_from_login_clicked', false, 200, 'login_page')
      .catch(error => console.error(error));
  
      analyticsClickEvent('apply_now_from_login', '');
    }

    backgroundLogin = () => {
        return <div className="col-md-6 col-xs-12 col-sm-12 section-left p-0">
        <div className="leftLogin-img-sec">
            <img src="images/humm-leftPanel-bg.jpg" alt="Background" />


        </div>
    </div>;
  }


  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.customPath} />;
    }
    return (
      <div className="layout-fixed">
        {/* loading for apple login */}
        <Loader loaderStatus={this.state.loading} />
        <div className="wrapper login-screen scroll-sec-fix">
          {this.backgroundLogin()}
          <div className="col-md-6 col-sm-12 col-xs-12 section-right p-0">
              <a className='help-support' href="https://www.shophumm.com/uk/help-centre/" 
              target="_blank" rel="noopener noreferrer">
                  <img src="images/help-support.svg" className="img-fluid" alt="logo" />
              </a>
            <div className="section-form--login-page" id="login-page">
              <div className="form-header">
                <span className="section-logo">
                  <img src="images/logo.svg" className="img-fluid" alt="logo" />
                </span>
                <span className="section-heading mt-0 mb-0">
                  <h4>
                    Let's get 
                    <br /> logged in!
                  </h4>
                </span>

              </div>

                <div className="form-body">
                <form id="login-form-page" className="log-form" name="loginForm">
                  <div className="error-user error-message-Qr email-input">
                    <div className="form-group mb-0">
                      <input type="email" className="form-control" id="email" name='email' autoComplete="on" required value={this.state.email} placeholder="Email"  onFocus={this.handleFocusForLogin}
                      onChange={(e) => this.handleChange(e)} onClick={(_e) => analyticsClickEvent('login_email', '')} 
                      onBlur={this.blurEvent} />
                    </div>
                    {/* email message */}
                      <div className="login--page">
                        <p className="error-message mt-0">
                      {this.state.errors.emailError &&
                        <span style={{ color: 'Red' }}>{this.state.errors.emailError}</span>
                      }
                        </p>
                      </div>
                  </div>
                  <div className="error-user password-input">
                    <div className="form-group mb-0">
                      <input type={this.state.inputType ? "text" : "password"} className="form-control" id="pwd" autoComplete="on" name='password' value={this.state.password} placeholder="Password" required onChange={(e) => this.handleChange(e)} onFocus={this.handleFocusForLogin}
                      onClick={(_e) => analyticsClickEvent('login_password', '')}
                      onBlur={this.blurEvent} />
                      <div className="input-group-append">
                        {this.state.inputType && (
                          <span className="view-icon" role="button" tabIndex={0} onKeyDown={(e) => { e.preventDefault(); }} onClick={this.inputChangeHandler}><img src="images/eye-icon-password-slashed.svg" width={28} height={32} alt="logo"/> </span>)}
                        {!this.state.inputType && (<span className="view-icon" role="button" tabIndex={0} onKeyDown={(e) => { e.preventDefault(); }} onClick={this.inputChangeHandler}> <img src="images/eye-icon-password.svg" alt="logo" /> </span>)}
                      </div>
                    </div>
                    {/* password message */}
                    <div className="login--page">
                      <p className="error-message mt-0">
                      {(this.state.errors.passwordError || this.state.serverError) &&
                        <>
                         <span style={{ color: 'Red' }}>{this.state.errors.passwordError}</span>
                         <span style={{ color: 'Red' }}>{this.state.serverError}</span>
                        </>
                        }
                      </p>
                    </div>
                  </div>
                  <div className="section-forgot-psw">
                    <Link to='/forgot-password' onClick={this.analyticseveeent}>
                      <p>Forgot Password</p>
                    </Link>
                  </div>
                  <div className="form-group mb-0">
                    <input type="submit" className={`btn btn-warning form-control ${this.state.disable} `} id="submit" value="Log In" onClick={(e) => { this.handleSubmit(e).catch(error => console.error(error)) }} />
                  </div>

                  <div className="social-accounts">
                    <div className="social-acc-content">
                      <h6>Or log in with ease via social media</h6>
                    </div>
                    <SocialMedia fromWhere={'/login'} appleResponseRecived={this.appleResponseRecivedLoader} />
                  </div>

                  <div className='application-navigate-link'>
                    <Link className='apply-important-info-link' to="/application" onClick={this.applyNowFlow}>
                      Want to apply? Click here
                    </Link>
                  </div>

                </form>
              </div>

              <div className="page-footer--login">
                <p className>
                humm is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478.
                </p>
              </div>
              
            </div>
         </div>

        {/* model */}
        <div className="modal fade model-error" id="loginModel" tabIndex="-1" aria-labelledby="loginModelLabel" aria-hidden="true">
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-body">
                <div className="logo">
                  <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                </div>
                <h5 className="modal-title " id="loginModelLabel">Message</h5>
                <p className="text-center mt-4">{this.state.hasEmailMessage}</p>
              </div>
              <div className="modal-footer mb-4">
                <button type="button" className="btn btn-primary" onClick={() => this.redirectForgetPasswordScreen()}>Ok</button>
              </div>
            </div>
          </div>
        </div>
        {/* invalid password message */}
        <div className="modal fade model-error" id="serverErrorShowModel" tabIndex="-1" data-keyboard="false" aria-labelledby="serverErrorShowModellLabel">
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-header">
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                </div>

                <p className="text-center mt-4 mb-0">{parse(this.state.loginServerError)}</p>
              </div>

              <div className="modal-footer mb-4">
                <button type="button" className="btn btn-primary" data-dismiss="modal">Ok</button>
              </div>


            </div>
          </div>
        </div>
      </div>
      </div>

    );
  }
}

  export const mapStateToProps = (state) => {
     return {
         loginStore: state.dataStore.loginStore,
         profilePicture:state.dataStore.profilePicture,
         customPathname: state.dataStore.customPathname
     };
    };


  export const mapDispatchToProps = (dispatch) => {
     return {
         manualLogin: (formData) => {
             return dispatch(manualLogin(formData));
         },
         setProfilePic:(userPic)=>{
          return dispatch({
              type: 'SET_PROFILE_PIC',
              value: userPic
          });
        },
        setCustomPath:(data)=>{
          return dispatch({
              type: 'SET_CUSTOM_PATH',
              value: data
          });
        }
     }
    }

Login.propTypes = {
  manualLogin: PropTypes.func,
  setProfilePic: PropTypes.func,
  setCustomPath: PropTypes.func,
  profilePicture: PropTypes.string,
  customPathname: PropTypes.string
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);