import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const getViewPartnerSearch = (formData) => {
  
    let url = config.hummBackendAppURL+'viewed-partner/search-partner'
    return async (dispatch) => {
        let viewPartnerSearchResponse=[];
        await axios.post(url,  formData,{headers:sessionID()} )
            .then(response => {
                dispatch({
                    type: 'VIEWED_SEARCH_PARTNER',
                    value: response.data
                });
                viewPartnerSearchResponse= response;
                return viewPartnerSearchResponse;

            })
            .catch(async error => {
                await userLogs(url,formData,error);
                if(error.response){
        handlingSession(error.response.status);
        }
                viewPartnerSearchResponse = error.response;
                return viewPartnerSearchResponse;
            })
            return viewPartnerSearchResponse; 
    }
};