import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const getViewPartner = (formData) => {
  
    let url = config.hummBackendAppURL+'viewed-partner/list'
    return async (dispatch) => {
        let viewPartnerResponse=[];
        await axios.post(url,  formData,{headers:sessionID()} )
            .then(response => {
                dispatch({
                    type: 'VIEWED_PARTNER',
                    value: response.data
                });
                
                viewPartnerResponse = response;
                return viewPartnerResponse;

            })
            .catch(async error => {
                await userLogs(url,formData,error);
                if(error.response){
                handlingSession(error.response.status);
                }
                viewPartnerResponse = error.response;
                return viewPartnerResponse;
            })
            return viewPartnerResponse;
    }
};