import React from 'react';
import Loader from '../../loader/loader';
import { analyticsClickEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {socialLogin}   from "../../../action/auth/socialLogin";
import getApplicationBannerImg from '../../../utils/getApplicationBannerImg';
import Cookies from 'universal-cookie';
import parse from 'html-react-parser';
import { extractEmail } from '../../../utils/extractEmail';
import { handleStatusCode } from '../../../utils/handleStatusCode';
import { resendOTP } from '../../../action/application/resendOTP';
import Reaptcha from 'reaptcha';
import { verifyOTP } from '../../../action/application/verifyOTP';
import { GTMApplicationSubmitStatus, GTMServerSubmitError } from '../../../utils/GtmDatalayer';
import { sendMobileOTP, verifyMobileOTP } from '../../../action/application/mobileOTP';
import { PropTypes } from 'prop-types';
import { frontEndLogs } from '../../../action/logs/frontEndLogs';

export class PhoneVerification extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            phoneNo: '',
            userId: props.userId,
            displayPhoneError: '',
            otpErrorMessage: '',
            code: '',
            codeErrorForResendCode: '',
            otpVerifyStatus: '',
            otpStatusCode: '',
            serverErrorMessage: '',
            redirect: false,
            disableField: false,
            disableVerifyOkButton: false,
            captchaReady: false,
            verified: false,
            resendFlag: false,
            phoneNumberFlag: false,
            verfiyOTPFlag: false,
            verificationMessage: '',
            redirectURL: '',
            popupButtonLabel: '',
        }
        this.reCaptchaRef = null

        this.backgroundImageDisplay = this.backgroundImageDisplay.bind(this);
        this.onKeyPressData_Phone = this.onKeyPressData_Phone.bind(this);
        this.onlyAcceptInputs_phone = this.onlyAcceptInputs_phone.bind(this);
        this.handleChangeInputForPhone = this.handleChangeInputForPhone.bind(this);
        this.handlingValidations = this.handlingValidations.bind(this);
    
    }

    backgroundImageDisplay=()=>{
        return <div className="col-md-6 col-sm-12 col-xs-12 section-left p-0">
            <div className="leftLogin-img-sec">
                <img src="images/humm-login-bg.jpg" alt="Background" />
            </div>
        </div>;
    }


    onKeyPressData_Phone = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let invalidChars = [
          "e",
          "-",
          "+",
          ",",
          ".",
          "''"
        ];
        if (invalidChars.includes(event.key)) {
          event.preventDefault();
        }
        if (/[a-zA-Z]/.test(event.key)) {
            event.preventDefault();
        }
        if (name === 'otp_verify_code') {
          if (!/\d/.test(event.key)) {
            event.preventDefault();
            return false;
          }
          
          if (this.state.code !== '') {
            this.setState({ otpErrorMessage: '' });
          } else {
            this.setState({ otpErrorMessage: value });
            this.setState({ otpErrorMessage: 'Verification code is required.' });
            return false;
          }
    

        }
      }

    handlePhoneNumberFlag = () => {
        if(this.state.phoneNumberFlag){
            if(localStorage.getItem('SocialType')){
                this.makeAPICallForSocialUserPhoneNumber().catch(error => console.error(error));
                return false;
            }
            this.handleSubmit()
            .catch(error => console.error(error));
        }
    }

    onLoad = () => {
        this.setState({
          captchaReady: true
        });
    };

    onVerify = (getToken) => {

        // If the reCaptcha is loaded and token is generated, save the token to the state reCaptchaToken
        if(getToken != null && getToken !== '' && this.state.captchaReady){
            this.setState({verified: true})
            this.setState({reCaptchaToken: getToken}, () => {
                
                this.handlePhoneNumberFlag();

                if(this.state.resendFlag){
                    if(localStorage.getItem('SocialType')){
                        this.makeAPICallForResendOTPSSOUser().catch(error => console.error(error));
                        return false;
                    }
                    this.resendCodeForOTP()
                    .catch(error => console.error(error));
                }

                this.handleVerifyOTPFlag(getToken);
            });

        }
        else {
            this.setState({verified: false})
        }

    }

    generateSessionForSSOUser = async() => {
        const obj = {
            'username': localStorage.getItem('UserName'),
            'email': localStorage.getItem('email'),
            'uu_id': localStorage.getItem('uu_id'),
            'mobile_number': this.state.phoneNo,
            'provider': localStorage.getItem('SocialType'),
            'Application_Channel': 'Web',
        }

        const socialLoginResult = await this.props.socialLogin(obj);

        if (socialLoginResult.data.statusCode === 200 || socialLoginResult.data.statusCode === 201) {
            localStorage.setItem('token',socialLoginResult?.data?.data?.access_token);
            localStorage.setItem('email',socialLoginResult?.data?.data?.user_details?.email);
            localStorage.setItem('UserName',socialLoginResult?.data.data?.user_details?.username);
            localStorage.setItem('sessionId',socialLoginResult?.data?.data?.session_id);
            localStorage.setItem('csrfToken',socialLoginResult?.data?.data?.csrf_token);
            localStorage.setItem('userExists',socialLoginResult?.data?.data?.user_exists);

            if(localStorage.getItem('userExists') != '' && 
            localStorage.getItem('userExists') != null && 
            localStorage.getItem('userExists') != undefined) {
                this.props.unsetProfilePic();
                getApplicationBannerImg().catch(error => console.error(error));
            }
            
            setTimeout(() => {
                // Hide the popup before page redirection
                window.$('#mobileVerifyModal').modal('hide');
                this.setState({loading: false });
                window.scrollTo(0, 0);
            
                /* Set the variable mobileRequired to false before setting state redirect as true 
                to display navigation menu while logging in */
                localStorage.setItem('mobileRequired',false);
                this.setState({redirect: true});
            }, 2800);

            
            return false;
        }    

    }

    handleVerifyOTPFlag = (recaptchaToken) => {
        if(this.state.verfiyOTPFlag){
            if(localStorage.getItem('SocialType')){
                this.makeAPICallForVerifyOTP(recaptchaToken)
                .catch(error => console.error(error));
                return false;
            }

            this.redirectCodeScreen()
            .catch(error => console.error(error));
        }
    }

    handleVerifyOTP = () => {

        if(window.$('#registerModal').hasClass('show') === true){
            analyticsClickEvent('email_verify_ok_button','')
        }

        if(window.$('#mobileVerifyModal').hasClass('show') === true){
            analyticsClickEvent('mobile_verify_ok_button','')
        }
        
        this.setState({ codeError: '', disableVerifyOkButton: true})

        if (this.state.code === '') {
            this.setState({ codeError: 'Verification code is required.', loading: false,
             disableVerifyOkButton: false, codeSuccess: '' });

            if (window.$('#registerModal').hasClass('show') === true) {
                GTMServerSubmitError('Email Verification', 'client',"Verification code is required.", 1, 3, '1 : 3');
            } else {
                GTMServerSubmitError('Mobile Verification', 'client',"Verification code is required.", 1, 3, '1 : 3');
            }

            this.setState({ loading: false });
            return false;
        }
        else {
            this.reCaptchaRef.execute();
            this.setState({verfiyOTPFlag: true, disableField: true});
        }
    }

    makeAPICallForVerifyOTP = async(recaptchaToken) => {
        analyticsClickEvent('register_verifySSO_mobile_popup_ok','')

        this.setState({disablePopupRegistration:'disable-popup'});

        const payloadObject = {
            "Email_Address": localStorage.getItem('email'),
            "Mobile_Number": this.state.phoneNo,
            "verify_code": this.state.code,
            "recaptchaToken": this.state.reCaptchaToken ? this.state.reCaptchaToken : recaptchaToken

        }
        frontEndLogs('sent request before verifying mobile OTP', false, 200, 'makeAPICallForVerifyOTP',payloadObject);
        this.setState({disableVerifyOkButton: true});
        const userVerifyOtpResult = await verifyMobileOTP(payloadObject)
        this.setState({verifyOTP: false});
        if(userVerifyOtpResult.status == 200){
            this.setState({verificationMessage: userVerifyOtpResult.data.message, codeSuccess: '', codeErrorForResendCode: ''});
            this.setState({otpVerify: true})

            setTimeout(() => {
                this.setState({loading: true})
                this.generateSessionForSSOUser();
            }, 3600);
            
        }
        else {
            this.setState({disableVerifyOkButton: false, codeSuccess: '',codeErrorForResendCode: '', disableField: false});
            this.setState({otpVerify: false,
            otpVerifyStatus: userVerifyOtpResult.status,codeError: userVerifyOtpResult.data.message});
            this.setState({ loading: false })
            this.setState({ otpErrorMessage: userVerifyOtpResult.data.message })
        }
    }

    convertMessage = (msg) => {
        if (extractEmail(msg) != null) {
          let newtext = '';
          let val = extractEmail(msg);
          val.map((src) => {
            let anchorTag = "<a target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
            newtext = msg.replace(src, anchorTag);
            msg = newtext;
    
          })
        }
        return msg;
    }

    makeAPICallForSocialUserPhoneNumber = async() => {
        let requestObject = {
            "email": localStorage.getItem('email'),
            "Mobile_Number": this.state.phoneNo,
            "recaptchaToken": this.state.reCaptchaToken
        }
        const userSendOTPResult = await sendMobileOTP(requestObject);
        this.setState({phoneNumberFlag: false});
        if(userSendOTPResult.data.statusCode == 200){
            this.setState({loading: false});
            window.$('#mobileVerifyModal').modal('show');
            return false
        }
        else if(userSendOTPResult.data.statusCode == 403){
            let resultErrorMessage = userSendOTPResult.data.message;
            if (extractEmail(resultErrorMessage) != null) {
                let extractErrorMessage = extractEmail(resultErrorMessage);
                extractErrorMessage.map((source) => {
                let anchorTag = "<a target='_blank' style='color: #ff6200' href='https://mail.google.com/mail/u/0/?fs=1&to=" + source + "&su=Enquiry&tf=cm'>" + source + "</a>"
                resultErrorMessage = resultErrorMessage.replace(source, anchorTag);
                })
            }
            this.setState({serverErrorMessage: resultErrorMessage})
            
            window.$('#mobileErrorModal').modal('show');
        } else {
            this.setState({displayPhoneError: userSendOTPResult.data.message, loading: false})
        }
    }

    redirectCodeScreen = async(_e) => {

        this.setState({disablePopupRegistration:'disable-popup'});

        const obj = {
            verify_code: this.state.code,
            Application_Channel: "Web",
            "dataToken": this.state.userToken,
            recaptchaToken: this.state.reCaptchaToken
        }
        
        const verifyResult = await verifyOTP(obj);
        
        this.setState({verfiyOTPFlag: false}); 
        if(verifyResult.status == 200){
            this.setState({disableField: true});
            
            let message = verifyResult.data.data.verificationMessage
            const resultMessage = message.replace("\n", "<br>")
            this.setState({verificationMessage: resultMessage, codeSuccess: ''});


            if(verifyResult.data.data.sendOtpTo === 'Email') {
                this.setState({userToken: verifyResult.data.data.dataToken})               
                setTimeout(() => {
                    GTMApplicationSubmitStatus('Mobile verification','server', 1, 3, "1 : 3","[Mobile Verified]");
                    this.setState({loading: true})            
                    this.changePhoneNo('#mobileVerifyModal');
                }, 2000);

                setTimeout(() => {
                    this.setState({loading: false})
                    window.$('#registerModal').modal('show');  
                }, 3500);
                return false
            }

            if(!verifyResult.data.data.showMarketingConsent) {
                setTimeout(() => {
                    this.getGtmSuccessStatusForOtpVerify();

                    this.changePhoneNo('#mobileVerifyModal');
                    this.changePhoneNo('#registerModal');
                    this.props.history.push('application');
                }, 2000);
            }


            if(localStorage.getItem('userExists') != '' && localStorage.getItem('userExists') != null && 
            localStorage.getItem('userExists') != undefined) {
                getApplicationBannerImg()
                .catch(error => console.error(error));
            }
            
            return false;
            
        }
        else if(verifyResult.data.statusCode === 400) {
            this.setState({disableField: false})
            if(verifyResult.data.data.verificationMessage !== '' && verifyResult.data.data.verificationMessage !== undefined) {
              let resultmessage = verifyResult.data.message;
              resultmessage = this.convertMessage(resultmessage);
      
              let message = verifyResult.data.data.verificationMessage;
              const verificationMessage = message.replace("\n", "<br>")
              this.setState({verificationMessage: verificationMessage, popupButtonLabel: verifyResult.data.data.buttonLabel, redirectURL: verifyResult.data.data.redirectUrl});
              this.setState({serverErrorMessage: resultmessage, validateStatusCode: verifyResult.data.statusCode,
                codeSuccess: '', codeErrorForResendCode: ''});
              setTimeout(() => {
                this.getGtmSuccessStatusForOtpVerify();
                this.changePhoneNo('#mobileVerifyModal');
                this.changePhoneNo('#registerModal');
                this.setState({loading: true})
              }, 2000);
      
              setTimeout(() => {
                this.setState({loading: false})
                window.$('#mobileErrorModal').modal('show');
              }, 2700);
            }
            else {
              this.setState({disableVerifyOkButton:false})
              this.setState({codeError: verifyResult.data.message, codeSuccess: '', codeErrorForResendCode: ''});

              this.getGtmServerErrorForOtpVerify(verifyResult.data.message);
            }
        }
        else {
            this.setState({disableVerifyOkButton: false, disableField: false});
            this.setState({codeError: verifyResult.data.message});
            this.getGtmServerErrorForOtpVerify(verifyResult.data.message);
            this.setState({ loading: false })
        }
    }

    // Push GTM error event to sent the error message from the response of the API call 'user/verifyOTP'
    getGtmServerErrorForOtpVerify = (errorMessage) => {
        if (window.$('#registerModal').hasClass('show') === true) {
            GTMServerSubmitError('Email Verification', 'server',errorMessage, 1, 3, '1 : 3');
        } else {
            GTMServerSubmitError('Mobile Verification', 'server',errorMessage, 1, 3, '1 : 3');
        }
    }

    // Push GTM success status when the OTP is verified based on currently displayed popup
    getGtmSuccessStatusForOtpVerify = () => {
        if (window.$('#registerModal').hasClass('show') === true) {
            GTMApplicationSubmitStatus('Email verification','server', 1, 3, "1 : 3","[Email Verified]");
        } else {
            GTMApplicationSubmitStatus('Mobile verification','server',1, 3, "1 : 3","[Mobile Verified]");
        }
    }

    onlyAcceptInputs_phone = (e) => {
        if (!/\d/.test(e.clipboardData.getData('Text'))) {
            e.preventDefault();
        }  
        if (/[a-zA-Z]/.test(e.clipboardData.getData('Text'))) {
            e.preventDefault();
        }
        
    }
      handleChangeInputForPhone(event) {
        if (event.target.value.length >= 12) {
          return false
        }
        if (event.target.value.length != 0) {
          if (!Number(event.target.value) && event.target.value != 0) {
            event.preventDefault();
            return;
          }
        }
    
        this.setState({ phoneNo: event.target.value });
        this.handlingValidations(event.target.value);
      }
    
      handlingValidations = (value) => {
        let mystring = value.substring(0, 2);
        let error = this.state.displayPhoneError;
        if (value === '') {
            error = 'Enter a mobile number'
            this.setState({ displayPhoneError: error })
        } else if (value.length !== 11) {
            error = 'Invalid UK mobile number format'
            this.setState({ displayPhoneError: error})
        } else if (mystring !== '07') {
            error= 'Enter a valid mobile number'
            this.setState({ displayPhoneError: error})
        } else {
            error = ''
            this.setState({ displayPhoneError: error})
        }
    
      }

      onBlurPushGTMError = () => {
        if(this.state.displayPhoneError !== ''){
            GTMServerSubmitError('Mobile Verification', 'client',this.state.displayPhoneError, 1, 3, '1 : 3');
            return false;
        }
      }

      handlePhoneSubmit = () => {
        analyticsClickEvent('mobile_verify_mobile_send','')
        if(this.state.phoneNo == '' ){
            this.setState({displayPhoneError: 'Enter a mobile number'})
            return false
        } else if(this.state.displayPhoneError !== '') {
            return false
        }
        else {

            this.reCaptchaRef.execute();
            this.setState({displayPhoneError: '', phoneNumberFlag: true, codeSuccess: '', loading: true});

        }
      }

      handleSubmit = async() => {

        let obj = {
            "Mobile_Number": this.state.phoneNo,
            "Application_Channel": "Web",
            "recaptchaToken": this.state.reCaptchaToken
        }

        const result = await resendOTP(obj);
        this.setState({phoneNumberFlag: false});

        if(result.status == 200){
            this.setState({userToken: result.data.data.dataToken, loading: false})
            if(result.data.data.sendOtpTo === 'Mobile'){
                window.$('#mobileVerifyModal').modal('show');
                return false
            }
            
            if(result.data.data.sendOtpTo === 'Email'){
                window.$('#registerModal').modal('show');
                return false
            }

        }
        else if(result.data.statusCode == 403){
            this.setState({loading: false})
            let resultErrorMessage = result.data.message;
            if (extractEmail(resultErrorMessage) != null) {
                let extractErrorMessage = extractEmail(resultErrorMessage);
                extractErrorMessage.map((source) => {
                let anchorTag = "<a target='_blank' style='color: #ff6200' href='https://mail.google.com/mail/u/0/?fs=1&to=" + source + "&su=Enquiry&tf=cm'>" + source + "</a>"
                resultErrorMessage = resultErrorMessage.replace(source, anchorTag);
                })
            }
            this.setState({serverErrorMessage: resultErrorMessage})
            
            window.$('#mobileErrorModal').modal('show');
        } else {
            this.setState({displayPhoneError: result.data.message, loading: false})
        }
            
      }

      redirectionForErrorPopupUpIfRedirectURLExists = () => {
        if(this.state.redirectURL !== '' && this.state.redirectURL !== undefined) {
          this.setState({ loading: true })
          window.location.href = this.state.redirectURL
          if(localStorage.getItem('fromWhere') === 'apply_now'){
            this.props.history.push("/login");
            window.location.reload(false)
          }
          else {
            this.props.history.push("/application");
          } 
        }
        else {
            if(localStorage.getItem('fromWhere') === 'apply_now'){
                this.props.history.push("/login");
                window.location.reload(false)
            }
            else {
                this.props.history.push("/application");
            }      
        }
      }

      redirectErrorMessagePopupClicked = () => {
        analyticsClickEvent('ok','')
        window.$('#commonModel').modal('hide');
        window.$('#userBalanceShowModel').modal('hide')
        window.$('.modal-backdrop').removeClass('show');
        window.$('body').removeClass('modal-open');
        window.$('.modal-backdrop').remove();
        if(this.state.validateStatusCode == 400){
            this.redirectionForErrorPopupUpIfRedirectURLExists();
        }
     }

     makeAPICallForResendOTPSSOUser = async() => {
        let resendOtpPayloadObject = {
            "email": localStorage.getItem('email'),
            "Mobile_Number": this.state.phoneNo,
            "recaptchaToken": this.state.reCaptchaToken
        }

        const resendOtpResult = await sendMobileOTP(resendOtpPayloadObject);
        this.setState({resendFlag: false});
        if (resendOtpResult.data.statusCode === 200) {
            this.setState({ codeSuccess: resendOtpResult.data.message, codeError: ''})
            this.setState({ codeErrorForResendCode: ''})
        }
        /* check whether the status code received matches in the list
        using handleStatusCode method and set the error message in variable codeErrorForResendCode
        */
        else if(handleStatusCode(resendOtpResult.data.statusCode)){
            this.setState({ codeSuccess: '', codeError: ''});
            this.setState({ codeErrorForResendCode: resendOtpResult.data.message});
        }
     }

      handleRecaptchaForResendOTP = (event) => {
        let text = event.target.innerText.split(' ').join('_');
        analyticsClickEvent(text.toLocaleLowerCase(),'')

        this.setState({reCaptchaToken: ''})
    
        this.reCaptchaRef.execute();
        this.setState({resendFlag: true})
      }

      resendCodeForOTP = async(_e) => {
        const objs = {
            "Mobile_Number": this.state.phoneNo,
            "Application_Channel": "Web",
            "recaptchaToken": this.state.reCaptchaToken
        }

        if(this.state.userToken !== '' && this.state.userToken !== undefined) {
            objs.dataToken = this.state.userToken;
        }

        const result = await resendOTP(objs);

        this.setState({resendFlag: false});

        if (result.data.statusCode === 200) {
            this.setState({ codeSuccess: result.data.message, codeError: ''})
            this.setState({ codeErrorForResendCode: ''})
        }
        /* check whether the status code received matches in the list
        using handleStatusCode method and set the error message in variable codeErrorForResendCode
        */
        else if(handleStatusCode(result.data.statusCode)){
            this.setState({ codeSuccess: '', codeError: ''});
            this.setState({ codeErrorForResendCode: result.data.message});
        }
    }

    changePhoneNo = (modalName) => {
        this.setState({code: '', codeError: '', codeSuccess: '', codeErrorForResendCode: '',verificationMessage: ''});
        this.setState({disableField: false, disableVerifyOkButton: false});
        window.$(modalName).modal("hide");
    }

    handleChangeOtp = async(event) =>{
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ disableRegistration: '' })
        this.setState({ disablePopupRegistration: '' })

        this.setState({ [name]: value });
        if (name === 'code') {
            if (!/\d/.test(value)) {
                event.preventDefault();
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.userId !== nextProps.userId) {
          return {
            userId: nextProps.userId
          };
        }
        
        return null;
    }

    leftBannerDisplay = () => {
        return localStorage.getItem('mobileRequired') ? this.backgroundImageDisplay() : '';
    }

    stylingChangesforBannerDisplay = () => {
        return localStorage.getItem('mobileRequired') ? `col-md-6 col-sm-12 col-xs-12 section-right p-0` : 
        `col-md-12 col-sm-12 col-xs-12 section-right p-0`;
    }

    headingChangesForPhoneVerification = () => {
        return localStorage.getItem('mobileRequired') ? 
        'account registration.' : ' application submission.';
    }

    displayResendSMS = () => {
        if(this.state.disableField) {
            return (
                <a id="resend-code" className="font-weight-bold" onKeyDown={(e) => { e.preventDefault(); }}
                    onClick={(e) => e.preventDefault()}>Resend SMS</a>
            );
        }
        else {
            return(
                <a id="resend-code" className="font-weight-bold" onKeyDown={(e) => { e.preventDefault(); }}
                onClick={(e) => this.handleRecaptchaForResendOTP(e)}>Resend SMS</a>
            );
        }

    }

    displayResendEmail = () => {
        if(this.state.disableField) {
            return (
                <a id="resend-code" className="font-weight-bold" onKeyDown={(e) => { e.preventDefault(); }}
                    onClick={(e) => e.preventDefault()}>Resend Email</a>
            );
        }
        else {
            return(
                <a id="resend-code" className="font-weight-bold" onKeyDown={(e) => { e.preventDefault(); }}
                onClick={(e) => this.handleRecaptchaForResendOTP(e)}>Resend Email</a>
            );
        }

    }

    displayChangeMobileNumber = () => {
        if(this.state.disableField){
            return (
                <a className="emailChange" onKeyDown={(e) => { e.preventDefault(); }} onClick={(e)=> e.preventDefault()}>
                                Change mobile number</a>
            );
        }
        else {
            return(
                <a className="emailChange" onKeyDown={(e) => { e.preventDefault(); }} onClick={()=> this.changePhoneNo("#mobileVerifyModal")}>
                    Change mobile number</a>
            )
        }
    }

    render() {

        const cookies = new Cookies();
        if (this.state.redirect) {
            switch (localStorage.getItem('userExists')) {
                case 'true':
                  if (cookies.get('retailerId') == undefined) {
                    return <Redirect push to='/welcome' />;
                  } else {
                    return <Redirect to='/application' />;
                  }
                case 'false':
                  return <Redirect to='/application' />;
                default:
                  return <Redirect to='/application' />;
            }
        }
        return (
            <>
            <div className='layout-fixed'>
            <div className='wrapper login-page scroll-sec-fix'>
                <Loader loaderStatus={this.state.loading} />
                {this.leftBannerDisplay()}
                <div className={this.stylingChangesforBannerDisplay()}>
                    <div className="section-form">                        
                        <div className="form-row align-items-end m-0">
                            <div className="form-header">
                                {localStorage.getItem('mobileRequired')  && 
                                <span className="section-logo">
                                    <img src="images/logo.svg" alt="logo"/>
                                </span>
                                }
                                <span className="section-heading text-center">
                                    <h4 className>Verify your mobile number for 
                                        <br />
                                    {this.headingChangesForPhoneVerification()}
                                     </h4>
                                </span>
                            </div>
                            <div className="application-phone-screen">
                                <form id="login-form" className="log-form" name="loginForm">
                                    <div className="error-user">
                                        <div className="form-group floating-label-group">
                                            <input type="text" className="form-control" id="phoneNumber" name='phoneNo'  
                                            autoComplete='none'
                                            value={this.state.phoneNo} 
                                            placeholder=" " required
                                            onChange={(e) => this.handleChangeInputForPhone(e)}
                                            onPaste={(e) => this.onlyAcceptInputs_phone(e)}
                                            onBlur={ () => this.onBlurPushGTMError() }
                                            onClick={(_e)=>analyticsClickEvent('mobile_number','')}/>
                                            <label className="floating-label">Mobile</label>

                                            <p className="error-message">{this.state.displayPhoneError !== '' &&
                                            <span style={{ color: 'Red' }}>{this.state.displayPhoneError}</span>
                                            }
                                            </p> 
                                        </div>

                                        {(localStorage.getItem('sessionId') !== null  || localStorage.getItem('SocialType'))
                                        &&<Reaptcha
                                            ref={event => (this.reCaptchaRef = event)}
                                            size="invisible"
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            badge="bottomright"
                                            onLoad={this.onLoad}
                                            onVerify={this.onVerify}
                                        />
                                        } 
                                        
                                    </div>
                                    <div className="form-group mt-4 mb-0">
                                        <input type="button" className="btn send-button form-control text-center" required id="submit" value="Send" onClick={(e) => this.handlePhoneSubmit()} />
                                    </div>  
                                </form>
                            </div>                            
                        </div>                        
                    </div>
                </div>
            </div>
            </div>
            <div className="modal fade" tabIndex="-1" aria-labelledby="mobileModalLabel" aria-hidden="true" data-backdrop="static" id="mobileVerifyModal" data-keyboard="false">
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" className="img-fluid" alt="logo" />
                                </div>
                                <button type="button" className="close" aria-label="Close" onClick={()=> this.changePhoneNo("#mobileVerifyModal")}
                                    style={{position: "absolute", top: "0", right: "0"}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 className="modal-title" id="mobileVerifyModalLabel">Verification SMS</h5>
                                <p className="mb-1 mt-2">Now let's verify your mobile number.</p>
                                <p className='bold-text'>We sent a code to <b>{this.state.phoneNo}</b>, enter it below.</p>
                                <input 
                                className="form-control" 
                                type="text" 
                                id="code" 
                                name="code" 
                                placeholder="Code"
                                value={this.state.code} 
                                disabled={this.state.disableField}
                                onPaste={(event) => { this.onlyAcceptInputs_phone(event) }} required
                                onChange={(event) => this.handleChangeOtp(event)}  />
                            </div>
                            {this.state.codeError !== '' &&
                                <p className="text-center modal-error-msg">
                                    <span className='error-message' >{this.state.codeError}</span>
                                </p>
                            }
                            <p> Didn't receive SMS?</p>
                            <p className="text-center link--block mb-0">
                                {this.displayResendSMS()}

                                {this.displayChangeMobileNumber()}
                            </p>
                            <p className="text-center modal-error-msg mt-3 mb-1">
                            {/* Display the success message when the message is not empty */}
                            {this.state.codeSuccess !== '' &&
                                <span className="otp-message">{this.state.codeSuccess}</span>
                            }
                            
                            {/* Display the error message when the message is not empty */}
                            {this.state.codeErrorForResendCode !== '' && 
                                <span style={{color: '#ff0000'}}>{this.state.codeErrorForResendCode}</span>
                            }

                                <span className='succes-message mb-0'>{parse(this.state.verificationMessage)}</span>
                            </p>

                            <div className="modal-footer">
                                <button type="submit" id='moible-redirect-screen' className={`${this.state.disablePopupRegistration === 'disable-popup' ? 'btn btn-primary disable-popup' : 'btn btn-primary'}`} disabled={this.state.disableVerifyOkButton} onClick={(e) => this.handleVerifyOTP(e)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                
            {/* Email Verification popup */}
            <div className="modal fade" id="registerModal" tabIndex="-1" aria-labelledby="registerModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>

                            <button type="button" className="close" aria-label="Close" onClick={()=> this.changePhoneNo("#registerModal")}
                                style={{position: "absolute", top: "0", right: "0"}}>
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <h5 className="modal-title" id="registerModalLabel">Verification Email</h5>
                            <p className='bold-text'>We sent you a code. Enter it below to verify - 
                             <b>{localStorage.getItem('email')}</b>
                            </p>
                            <input className="form-control" type="text" id="code" name="code" placeholder="Code"
                                value={this.state.code} onPaste={(e) => { this.onlyAcceptInputs_phone(e) }}
                                required onChange={(e) => this.handleChangeOtp(e)} />


                            
                        </div>
                        <p className="text-center modal-error-msg">{this.state.codeError !== '' &&
                            <span style={{ color: 'Red' }}>{this.state.codeError}</span>
                        }</p>
                        <p>Didn't receive email?</p>
                        <p className="text-center link--block mb-0">
                            {this.displayResendEmail()}
                            
                            {localStorage.getItem('sessionId') == null && <a className="emailChange" onKeyDown={(e) => { e.preventDefault(); }} onClick={()=> this.changePhoneNo("#registerModal")}>
                                Change email address</a>}
                        </p>
                        <p className="text-center modal-error-msg mt-3 mb-1">
                        {this.state.codeSuccess !== '' &&
                            <span>{this.state.codeSuccess}</span>
                        }
                            
                        {/* Display the error message when the message is not empty */}
                        {this.state.codeErrorForResendCode !== '' && 
                            <span style={{color: '#ff0000'}}>{this.state.codeErrorForResendCode}</span>
                        }

                        <span className='succes-message mb-0'>{parse(this.state.verificationMessage)}</span>

                        </p>

                            {localStorage.getItem('sessionId') !== null &&
                            <Reaptcha
                                ref={event => (this.reCaptchaRef = event)}
                                size="invisible"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                badge="bottomright"
                                onLoad={this.onLoad}
                                onVerify={this.onVerify}
                                    />
                            }

                        <div className="modal-footer">
                            <button type="button" id='register-redirect-screen' className={`${this.state.disablePopupRegistration === 'disable-popup' ? 'btn btn-primary disable-popup' : 'btn btn-primary'}`} disabled={this.state.disableVerifyOkButton} onClick={(e) => this.handleVerifyOTP(e)}>Ok</button>
                            
                        </div>
                    </div>
                </div>
            </div>
                {/* server error popup modal */}
                <div className="modal fade model-error" id="mobileErrorModal" tabIndex="-1" data-keyboard="false" data-backdrop="static" aria-labelledby="serverErrorShowModellLabel" aria-hidden="true">
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                                </div>
                                {/* Added the margin left and right spacing to message displayed in popup */}
                                <p className="text-center mt-4 mx-3">{parse(this.state.serverErrorMessage)}</p>
                            </div>

                            <div className="modal-footer mb-4">
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.redirectErrorMessagePopupClicked}>{this.state.popupButtonLabel !=='' ? this.state.popupButtonLabel : `Ok`}</button>
                            </div>


                        </div>
                    </div>
                </div>
            </>
        );
    }
} 



const mapStateToProps = (state) => {
    return {
        userId: state.dataStore.userId,
    };
    
};

const mapDispatchToProps = (dispatch) => {
    return {
        socialLogin: (formData) => {
            return dispatch(socialLogin(formData));
        },
        setUserId: (userID) => {
            return dispatch({
              type: 'USER_ID',
              value: userID
          });
        },
        unsetProfilePic: () => {
            return dispatch({
                type: 'UNSET_PROFILE_PIC'
            });
        }
    }
}

PhoneVerification.propTypes = {
    userId: PropTypes.string,
    socialLogin: PropTypes.func,
    history: PropTypes.object,
    unsetProfilePic: PropTypes.func,

}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerification);