import axios from 'axios';
import config from '../config';
import {sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


export const manualLogin = (formData) => {
  
    let url = config.hummBackendAppURL+'login'
    return async (dispatch) => {
        let resp = [];
        await axios.post(url,  formData,{headers:sessionID()} )
            .then(response => {
                dispatch({
                    type: 'LOGIN_STORE',
                    value: response.data
                });
                resp = response;
                return resp;
            })
            .catch(async error => {
                await userLogs(url,formData,error);
                resp = error.response;
                return resp;
            })

            return resp;
    }
};

export const emailCheck = async (formData) => {
    let url = config.hummBackendAppURL+'email-check'

   return axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        if(window.location.pathname.split('/')[1] === 'login'|| window.location.pathname.split('/')[1] === 'my-account') {
            await userLogs(url,formData,err);
        }
        return err.response
    })


};

export const twitterRequestToken = async  (formData) => {
    let url = config.hummBackendAppURL+'twitter-login/RequestToken'
    return axios.post(url,formData).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        return err.response
    })
 
};

export const twitterLogin = async  (formData) => {
      let url = config.hummBackendAppURL+'twitter-login/AccessToken'
      return axios.post(url,formData).then(res => {
          return res;
      })
      .catch(async err => {
        await userLogs(url,formData,err);
          return err.response
      })
  };

  export const getUserBlockStatus = async  (formData) => {
    let url = config.hummBackendAppURL+'user/getuserblockstatus'
    return axios.post(url,formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        return err.response
    })
};

// Apple login API
export const appleLogin = async (appleCode) => {
    console.log('appleLogin_api_appleCode',appleCode);
    let url = config.hummBackendAppURL+'user/apple_auth'

   return axios.post(url, appleCode,{headers:sessionID()}).then(res => {
    console.log('appleLogin_api_res',res);
        return res;
    })
    .catch(async err => {
console.log('appleLogin_api_error',err);
        return err.response.data
    })


};

  
