
import './App.css';
import Routes from './routes';
import React, { useEffect, useState } from 'react';
import branch from 'branch-sdk';
import { userLogs } from '../src/action/logs/userLogs';
import { frontEndLogs } from './action/logs/frontEndLogs';
import Loader from './component/loader/loader';
import Cookies from 'universal-cookie';

function App() {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {    
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); 
    let yyyy = today.getFullYear();
    let todayDate = mm + dd + yyyy;
  
    const hours = today.getHours(); 
    const minutes = today.getMinutes(); 
    const seconds = today.getSeconds();  
    const currentTimestamp = todayDate + hours + minutes + seconds
  
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "css/custom-style.css?v=" +currentTimestamp;
    link.type = "text/css";

    link.addEventListener('load', () => {
      setShowContent(true);
    })
    link.removeEventListener('load', () => {
      document.getElementsByTagName("head")[0].removeChild(link)
    })
    document.getElementsByTagName("head")[0].appendChild(link);
  
  }, []);

  if(window.location.search.includes('_branch_referrer')){
    const cookie = new Cookies();
    let searchParams = new URLSearchParams(window.location.search);
    let Dob = searchParams.get("dob"); 
    try {
      frontEndLogs('Branch SDK Initiated', false, 200, 'start_of_app');
      branch.init(process.env.REACT_APP_BRANCH_KEY, async function (err, data) {
        frontEndLogs('Branch SDK response', false, 200, 'start_of_app', { 'Error': err, 'Data': data });
        if (!err) {
          if (data.data_parsed.retailer_id != undefined && data.data_parsed.retailer_id !== '') {
            localStorage.setItem('storeID', data.data_parsed.retailer_id)
            if (Dob) {
              cookie.set('Dob', Dob, { path: '/', maxAge: 3600 });
            }
            await frontEndLogs('Retailer ID received from branch link ' + data.data_parsed.retailer_id, false, 200, 'start_of_app');
          }
          else {
            await userLogs('', data.data_parsed, '', 'Retailer ID not received from branch link', 404);
          }
        } else {
          frontEndLogs('Branch SDK initiate error', true, 400, 'start_of_app', err);
        }
      });
    } catch (error) {
      frontEndLogs('Branch SDK catch error', true, 400, 'start_of_app', error);
    }
    
  }
  return (
    <div>
    {showContent ? <Routes /> : <Loader loaderStatus={true} />}
    </div>
    
  );
}
export default App;
