import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { authMiddleware } from './utils/authMiddleware.js';
import { frontEndLogs } from "./action/logs/frontEndLogs.js";


const PrivateRoute: React.FC<{
    component: React.FC;
    path: string;
}> = (props) => {
        
    const condition = authMiddleware();
    const location = useLocation();
    const cookie = new Cookies();
    //Removed the Skip Login Checks

    let routeLinks=[
        '/favorite-partner',
        '/wallet',
        '/wallet-contract-detail',
        '/viewed-partner',
        '/my-account',
        '/home',
        '/shop','/application','/quote-calculator','/quote-calculator-results', '/mobile-verification'];

    

    setTimeout(() => {
        if(localStorage.getItem('storeID') !== '' && localStorage.getItem('storeID') != null){
            cookie.set('retailerId',localStorage.getItem('storeID'),{ path: '/' });

            if((props.path === '/application' && location.pathname == '/application') && cookie.get('retailerId') !== undefined ){
                frontEndLogs('Received retailer id is saved in website - '+cookie.get('retailerId'),false,200, 'privateRoute');
            }
            else if(props.path === '/application' && location.pathname == '/application') {
                frontEndLogs('Received retailer id is not saved in website - '+cookie.get('retailerId'),false,200, 'privateRoute');
            }
        }
    }, 1400);
    
    
    if(!routeLinks.includes(location.pathname)){
        return (<Redirect to="/page-not-found" />);
    }  

    let routeBlockApplyNow = [
        '/favorite-partner',
        '/wallet',
        '/wallet-contract-detail',
        '/viewed-partner',
        '/my-account',
        '/home',
        '/shop', '/mobile-verification'
    ];

    const routeCondition = condition ? (<Route path={props.path}  component={props.component} />) :
    (<Redirect to="/login" />)

    const applyFlowRedirection = (routeBlockApplyNow.includes(location.pathname) && localStorage.getItem('fromWhere') == 'apply_now' ) ? <Redirect to="/login" /> : routeCondition

    return applyFlowRedirection;
};
export default PrivateRoute;