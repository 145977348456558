import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const checkUserExistence = async (formData) => {
    let url = config.hummBackendAppURL+'user/check_user_existence';
   return axios.post(url, formData,{headers:sessionID('secured')}).then(checkApplicantResponse => {
        return checkApplicantResponse;
    })
    .catch(async error => {
        await userLogs(url,formData,error);
        if(error.response){
        handlingSession(error.response.status);
        }
        return error.response
    })

};