import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {addViewPartner} from '../../../action/partner/addViewPartners';
import {addFavoritePartner} from '../../../action/partner/addFavoritePartner';
import {removeFavoritePartner} from '../../../action/partner/removeFavoritePartner';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { Link } from "react-router-dom";
import { thirdPartyWebsiteAlertPopupText } from "../../../utils/const";
import { PropTypes } from 'prop-types';

export default class SimpleSlider extends Component {
    constructor(props) {
        super(props); 
        this.state={
            itemValue:'',
            clientXonMouseDown: null,
            clientYonMouseDown: null,
            value:'',
            isFavAdd:'',
            isFavRemove:'',
            itemList:[]
        }
        this.addViewedPartner =this.addViewedPartner.bind(this);
        this.handleFavoriteAdd = this.handleFavoriteAdd.bind(this)
        this.handleFavoriteRemove = this.handleFavoriteRemove.bind(this)
    }



    addViewedPartner = (partnerID,partnerName,partnerWebsitLink)=>{
      localStorage.removeItem("partnerWebsiteURL");
      localStorage.removeItem("partnerId");
      if(partnerWebsitLink == '' || partnerWebsitLink == null || partnerWebsitLink == undefined){
        return false;
      }
      let splitPartnerName = partnerName.split(' ').join('_');
      analyticsClickEvent('home_partner',splitPartnerName)

      const resultPartnerURL = this.checkURL(partnerWebsitLink);
      localStorage.setItem('partnerId', partnerID)
      localStorage.setItem("partnerWebsiteURL", resultPartnerURL);
      window.$('#thirdPartyWebsiteModel').modal('show');
   }

   handleOnMouseDown (e) {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    })
    e.preventDefault() // stops weird link dragging effect
  }

  handleFavoriteAdd=async(value,partnerName)=>{
    let splitPartnerName = partnerName;
    if(partnerName != undefined){
      splitPartnerName = partnerName.split(' ').join('_')
    }
    analyticsClickEvent('home_partner_favorite_heart_checked',splitPartnerName);
    const addDataParamater={email:localStorage.getItem('email'),account:value};
    const addFavoriteResponse = await addFavoritePartner(addDataParamater);
    const temporaryData = addFavoriteResponse.data;
    let currentState = this.state.itemList;
    let addFavoriteUpdated = currentState.map((item)=>{
        if(item.partnerId === temporaryData.account){
            item.partnerFavId = temporaryData.id
        
        }
        return item;
    })

     this.setState({itemList:addFavoriteUpdated});
     this.props.favCall();
   
}

handleFavoriteRemove=async(value,partnerName)=>{
  let splitPartnerName = partnerName; 
  if(partnerName != undefined) {
    splitPartnerName = partnerName.split(' ').join('_')
  }
    analyticsClickEvent('home_partner_favorite_heart_unchecked',splitPartnerName);
    const removeDataParam={email:localStorage.getItem('email'),account:value};

    removeFavoritePartner(removeDataParam).catch(error => console.error(error));

    let currentState = this.state.itemList;
    let removeFavUpdated = currentState.map((item)=>{
        if(item.partnerId === value){
            item.partnerFavId = null
        
        }
        return item;
    })

     this.setState({itemList:removeFavUpdated});
     this.props.favCall();
   
 }
 checkURL=(url)=>{
  if(url!=null && url!=undefined && url!=''){
     let pattern = /^((http|https|ftp):\/\/)/;

     if(!pattern.test(url)) {
      return "http://" + url;
     }else{
      return url;
     }
  }else{
     return url 
  }
 
}

  setCurrentLocationPath = () => {
    localStorage.setItem('prevRoute',window.location.pathname)
  }

  redirectionThirdPartnerSite = async () => {
    if(localStorage.getItem('fromWhere') != 'apply_now') {
      const requestObject = {
        email: localStorage.getItem('email'),
        account: localStorage.getItem('partnerId')
      }
      await addViewPartner(requestObject);
    }
    
    if(localStorage.getItem("partnerWebsiteURL")) {
      window.open(localStorage.getItem("partnerWebsiteURL"), '_blank', 'noopener noreferrer');
      window.$('#thirdPartyWebsiteModel').modal('hide');
    }

  }

  render() {
   let partnerDispaly='';
    if (Array.isArray(this.props.items) && this.props.items != 0) {
      const sortedItems = [...this.props.items].sort((a,b)=>(a.partnerName > b.partnerName ? 1 : -1));
      partnerDispaly = sortedItems;
    }
    const settings = {
        autoplay: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    
   

    return (
            <>
            <div>
              <Slider {...settings}>
                {partnerDispaly!=='' &&
                partnerDispaly.map(e =>                    
                  <div className="featured-box" key={e.partnerName}>
                  <div className="featured-secQr">
                      {e.partnerCoverImage==undefined &&
                            <img src="images/compu.png" alt={e.partnerName} />
                      }
                      {e.partnerCoverImage!=undefined &&
                            <img src={e.partnerCoverImage} alt={e.partnerName} 
                            onError={(evt) => evt.target.src = "images/partner-placeholder.png"} />
                      }
                      <div className="overlay">
                          </div> 
                          {localStorage.getItem('fromWhere')!='skip' &&
                          <div className="fav-icon-section">
                            {e.partnerFavId===undefined &&(<a value={e.partnerId} onKeyDown={(e) => { e.preventDefault(); }}  onClick={(events) => { this.handleFavoriteAdd(e.partnerId,e.partnerName).catch(error => console.error(error)) }} className='fav-icon-btn'><img src="images/heart-white.svg" /></a>) }
                                {e.partnerFavId!==undefined &&(<a value={e.partnerId} onKeyDown={(e) => { e.preventDefault(); }} onClick={(events) => { this.handleFavoriteRemove(e.partnerId,e.partnerName).catch(error => console.error(error)) }} className='fav-icon-btn active'><img src="images/heart.svg" /></a>) }
                      </div>
                    }
                  <a target rel="noreferrer"  href="javascript:void(0)"  key={e.partnerId}
                  onClick={()=>this.addViewedPartner(e.partnerId,e.partnerName,e.partnerWebSite)}  onMouseDown={(event) => {this.handleOnMouseDown(event)}}>
                  <span className="featured-caption">
                    <div className="slider-logo-image">
                      {e.partnerLogo!=null &&
                        <img src={e.partnerLogo} alt={e.partnerName} />
                      }
                      {e.partnerLogo==null &&
                      <h6>{e.partnerName}</h6>
                      }
                    </div>
                  </span>
                  </a>
                  <div className="qtwo-calculator">
                    <Link
                        to={{
                            pathname: "/quote-calculator",
                            state: { id: e.partnerId, store_Name: e.partnerName }
                        }}
                        onClick={this.setCurrentLocationPath}
                    >
                        <button className="qc-click-btn"
                        onClick={(_e) => { analyticsClickEvent('home_partner_quote_calculator', '') }}
                        >
                             <span className=''>
                                Get A Quote                                
                            </span>
                            <img src="images/right-arrow-shop.svg" className="d-inline" alt="icon" />
                        </button>
                    </Link>
                  </div>
              </div>
          </div>) 
        }
        </Slider>
      </div>
      <div className="modal fade model-error" id="thirdPartyWebsiteModel"  tabIndex="-1" data-keyboard="false" aria-labelledby="thirdPartyWebsitePopupLabel">
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-header">
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img src="images/modal-logo.svg"  className="img-fluid" alt="logo" />
                </div>

                <p className="mt-4 text-center mb-0">{thirdPartyWebsiteAlertPopupText}</p>
              </div>

              <div className="modal-footer mb-4">
                <button type="button" data-dismiss="modal" className="btn-warning btn btn-outline">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={() => {
                  this.redirectionThirdPartnerSite().catch(error => console.error(error)) }} >Ok</button>
              </div>


            </div>
          </div>
        </div>
      </>
    );
  }
}

SimpleSlider.propTypes = {
  favCall: PropTypes.func,
  items: PropTypes.array,

}