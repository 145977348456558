import React from 'react'

export class HeroSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = { contractSummary:'' }
    }

    async componentDidMount(){
        let details =localStorage.getItem('contractSummary');
        let contractSummaryResult= this.props.contractSummary?this.props.contractSummary:JSON.parse(details);
        console.log(contractSummaryResult);

        this.setState({contractSummary:contractSummaryResult});   
        console.log(this.state.contractSummary);
    }
    render() {
        let details = JSON.parse(localStorage.getItem('contractSummary'));
        console.log(details);
        return (

            <div className="row">
            <div className="col-12">
                <div className="wallet---itemQr">
                    <div className="wallet-container w-100 wallet--banner-img">
                        <div className="lr-item--hero">
                            <div className="hero-left">
                                <div className="hero-left-top">
                                    <div className="col-lg-4 col-md-4 user-info">
                                        <div className="user-image-area">
                                            {this.props.profilePicture!=='' && this.props.profilePicture!==null &&
                                            <img src={this.props.profilePicture}  className="img-fluid" alt="User"/>}
                                            {this.props.profilePicture ===null &&
                                            <div className="no-profile-image">
                                            {localStorage.getItem('UserName')!=='' && localStorage.getItem('UserName')!==null &&
                                              <span className="no-profile-image-letter-wallet">{localStorage.getItem('UserName').substring(0, 1).toUpperCase()}</span>
                                            }
                                            </div>
                                            }
                                        </div>
                                        <span>
                                            {localStorage.getItem('UserName')!=='' && localStorage.getItem('UserName')!==null &&
                                            <h6 className="wallet-user-name">Hi {localStorage.getItem('UserName')}!</h6>}
                                            {localStorage.getItem('UserName')===null &&
                                            <h6>Hi!</h6>}
                                        </span>
                                    </div>

                                        <div className="col-lg-8 col-md-8 pr-5 user-expense wallet-active-item">
                                            <div className="d-flex">

                                                <div className="total-to-spend">
                                                    <h6>Total To Spend</h6>
                                                  
                                                {details !== null && details.total_amount !== undefined && details.total_amount !== 0 &&
                                                <h2>£{details.total_amount}</h2>
                                                }
                                                 {details !== null && details.total_amount == undefined &&
                                                <h2>£0.00</h2>
                                                }
                                                 {details === null &&
                                                <h2>£0.00</h2>
                                                }
                                                    
                                                    {/* <h2>£{details.total_amount == null ? '0.00':'0.00'}</h2> */}
                                                </div>
                                                <div className="total-to-spend ml-4">
                                                    <h6>Total Outstanding</h6>
                                                    
                                                    <h2>£{details !== null ? details.total_outstanding : '0.00'}</h2>
                                                </div>
                                            </div>
                                            <div className="next-payment">
                                                <h6>Next payment</h6>
                                                {details !== null && details.next_payment_amount !== undefined && details.next_payment_amount !== '' && details.next_payment_amount !== 0 &&
                                                <h2>£{details.next_payment_amount}</h2>
                                                }
                                                 {details !== null && details.next_payment_amount == undefined &&
                                                <h2>£0.00</h2>
                                                }
                                                 {details !== null && details.next_payment_amount == ''  && details.next_payment_amount == 0 &&
                                                <h2>£0.00</h2>
                                                }
                                                 {details == null  &&
                                                <h2>£0.00</h2>
                                                }
                                                {/* <h2>£{details !== null?details.next_payment_amount:'0.00'}</h2> */}
                                                {details !== null && details.next_payment_date !== undefined &&
                                                 <span>{details !== null ?details.next_payment_date:''}</span>
                                                // <h2>£{details.next_payment_amount}</h2>
                                                }
                                                 {details !== null && details.next_payment_date == undefined &&
                                                <span>{details !== null ?details.next_payment_date:''}</span>
                                                }
                                                {details !== null && details.next_payment_date == '' &&
                                                <span>{details !== null ?details.next_payment_date:''}</span>
                                                }
                                                
                                            {/* <span>{details !== null ?details.next_payment_date:''}</span> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 

export default HeroSection;