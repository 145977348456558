import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Instructions from "./instuctions/instruction";
import parse from 'html-react-parser';
import { analyticsClickEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";
import { getStatus } from "../../action/application/getStatus";
import Cookies from 'universal-cookie';
import Loader from "../loader/loader";
import { frontEndLogs } from "../../action/logs/frontEndLogs";
import { PropTypes } from 'prop-types';

export class DocumentVerifyMobile extends Component {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            documentSteps: false,
            passportInstruction: false,
            drivinglicenseInstruction: false,
            selfieInstruction: false,
            documentType:'',
            instruction:true,
            instructionsSection:false,
            passportSection:false,
            drivinglicenseSection:false,
            isEnableDocumentsTabs:false,
            isSelectDocument:'',
            userLoginType:localStorage.getItem('userLoginType'),
            isLoading: false,
            isConnected: navigator.onLine,
        };

        this.handleChange = this.handleChange.bind(this);
        this.callSessionStatus = this.callSessionStatus.bind(this);
        this.getSessionStatus = this.getSessionStatus.bind(this);
        this.openinstruction = this.openinstruction.bind(this);
        this.passportInstructionOpen = this.passportInstructionOpen.bind(this);
        this.IDInstructionOpen = this.IDInstructionOpen.bind(this);
        this.veteranInstructionOpen = this.veteranInstructionOpen.bind(this);
        this.checkIntervalID = '';
    }

    componentDidMount(){
      window.dataLayer = window.dataLayer || {};
      this.setState({documentSteps:true,instruction:false});
      let applicationId = this.getApplicationIDInstorage();
      if(applicationId !== '' && applicationId !== null && applicationId !== undefined){
        this.sendDeviceDetectForHandoff();
      }

      this.callSessionStatus();

      window.dataLayer = window.dataLayer || {}; 
      if(localStorage.getItem('sessionId') !== '' && localStorage.getItem('sessionId') != null){
        window.dataLayer.push({ 
            "event" : "metadata", 
            "LoginStatus" : "TRUE"
        });
      }
      
      if(applicationId !== '' && applicationId != null && applicationId !== undefined){
        window.dataLayer.push({
            "event" : "metadata",
            "applicationID" : applicationId
        });
      }

      window.addEventListener("online", this.handleOnline);
      window.addEventListener("offline", this.handleOffline);
      frontEndLogs('All available document are showed '+applicationId,false,200,'componentDidMount');
    }

    callSessionStatus() {
      this.checkIntervalID = setInterval(this.getSessionStatus, 30000);
    }

    async getSessionStatus() {
      if(localStorage.getItem('sessionId') !== '' && localStorage.getItem('sessionId') != null){
        const sessionObj={
          "email":localStorage.getItem('email'),
          "application_id":await this.getApplicationIDInstorage()
        }
        const sessionResult = await getStatus(sessionObj);
        const cookie = new Cookies();    
        if(sessionResult.data.statusCode !== undefined){
          if(sessionResult.data.statusCode === 400 && sessionResult.data.message === "Invalid Session") {
            // Remove cookie for Retailer Id once the user session is expired
            cookie.remove('retailerId', { path: '/' });

            this.props.history.push('/login');
            window.location.reload(true);
          }
        }
      } 
    }

    componentWillUnmount(){
      clearInterval(this.checkIntervalID);
      clearInterval(this.intervalID);

      window.removeEventListener("online", this.handleOnline);
      window.removeEventListener("offline", this.handleOffline);
    }

    handleOnline = () => {
      this.setState({ isConnected: true });
    };
    
    handleOffline = () => {
      this.setState({ isConnected: false });
    };

    sendDeviceDetectForHandoff = async() => {
      await frontEndLogs('Mitek process in handoff - '+await this.getApplicationIDInstorage(),false,200,'sendDeviceDetectForHandoff');
    }

    openinstruction = (documenttype) => {
      if(documenttype === 'passport'){
        analyticsClickEvent('passport','')
      }else if(documenttype === 'ID'){
        analyticsClickEvent('driving_licence','')
      }else if(documenttype === 'VETERAN_CARD'){
        analyticsClickEvent('hm_forces_card','')
      }
        //  analyticsClickEvent('documentType_in_'+documenttype)
      this.setState({documentSteps:false,instructionsSection:true,isEnableDocumentsTabs:true,isSelectDocument:documenttype});
      }


      handleChange(value){
        console.log('statusValue',value)
        this.setState({isEnableDocumentsTabs:false,isSelectDocument:''})
        localStorage.setItem('applicationStatus','')
        if(value==='decliened'){
          localStorage.setItem('applicationStatus','decliened')
        }else if(value==='success'){
          localStorage.setItem('applicationStatus','success')
        }else if(value==='refer'){
          localStorage.setItem('applicationStatus','refer')
        }else{
          localStorage.setItem('applicationStatus','')
        }
        window.location.reload();
        
      }

      handleLoaderStatus = (value) => {
        this.setState({isLoading: value})
      }

      passportInstructionOpen(){
        this.openinstruction('passport')
        frontEndLogs('The user selected the passport document '+this.getApplicationIDInstorage(),false,200,'passportInstructionOpen');
      }

      IDInstructionOpen(){
        this.openinstruction('ID')
        frontEndLogs('The user selected the Driving License document '+this.getApplicationIDInstorage(),false,200,'IDInstructionOpen');
      }

      veteranInstructionOpen(){
        this.openinstruction('VETERAN_CARD')
        frontEndLogs('The user selected the veteran card document '+this.getApplicationIDInstorage(),false,200,'veteranInstructionOpen');
      }

      redirectToPurchaseURLIfExists = () => {
        frontEndLogs('The user selected the veteran card document '+this.getApplicationIDInstorage(),false,200,'redirectToPurchaseURLIfExists');
        window.location.href = localStorage.getItem('redirectPurchaseURL');

        localStorage.setItem('buttonLabel', '');
        localStorage.setItem('redirectPurchaseURL', '');
      }
      
      redirectToPurchaseURLAfterTimeout =() => {
        if(localStorage.getItem('redirectPurchaseURL') !== '' && localStorage.getItem('redirectPurchaseURL') !== null) {
            window.location.href = localStorage.getItem('redirectPurchaseURL');
        }
      }

      handleRetryLoaderStatus = (value) => {
        this.props.handleRetryLoader(value);
      }

      getApplicationIDInstorage(){
        const cookie = new Cookies();
        return localStorage.getItem('applicationId') ? localStorage.getItem('applicationId') :cookie.get('applicationId')
      }

    render() {
      const {isConnected} = this.state;
      const buttonLabel = localStorage.getItem('buttonLabel');
      if(localStorage.getItem('applicationStatus')===''){
        return (
          <>
          <div className={!isConnected ? 'd-block' : 'd-none'}>
            <div className="appfrom-section">
                  <div className="mit-container">
                      <div className="complete-section">
                          <div className="logo-area">
                              <img src="images/humm-logo.svg" alt="Humm" />
                          </div>
                          <div className="complete-content">
                              Please check your internet connection and try again.
                          </div>

                          <div className="modal-footer mb-4 mx-auto border-top-0">
                                <button className="btn btn-warning btn-outline" onClick={(_e) => { 
                                  window.location.reload()
                                  this.setState({isConnected: true})
                                   }}>Try Again</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className={isConnected ? 'd-block' : 'd-none'}>
          <Loader loaderStatus={this.state.isLoading} />
          <div className="step-final mobile-view-scan">
          {this.state.isEnableDocumentsTabs === false &&
          <div className="mit-container">
            <p className="mit-mobile-app">Finally, let’s upload your ID. Please select and upload one of the documents listed below.</p>
            <div className="mit-document-section">
              <div className="mit-inner-box">
                <Link to="#" onClick={this.passportInstructionOpen}>
                  <span className="inner-left">
                    <img src="images/passport.svg" alt="Card" />
                  </span>
                  <span className="inner-center">
                    <p>Passport</p>
                  </span>
                  <span className="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
              <div className="mit-inner-box">
                <Link to='#' onClick={this.IDInstructionOpen}>
                  <span class="inner-left">
                    <img src="images/driving-license.svg" alt="Card" />
                  </span>
                  <span class="inner-center">
                    <p>Driving Licence</p>
                  </span>
                  <span class="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
              <div class="mit-inner-box">
                <Link to="#" onClick={this.veteranInstructionOpen}>
                  <span class="inner-left">
                    <img src="images/hm-force-card1.svg" alt="Card" />
                  </span>
                  <span class="inner-center">
                    <p>HM Forces Card</p>
                  </span>
                  <span class="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
      }
      {/* {this.state.documentSteps === false && this.state.instructionsSection === true &&
    <div class="mit-container">
                   <div class="instructions-section">
                   <h4 class="application-qr-sub-title">INSTRUCTIONS</h4>
                   <div class="instruction-message">
                       <h6>Steps to capture the document correctly</h6>
                   </div>
                   <div class="suggetion-message">
                       <span class="icon-area">
                           <img src="images/progress-success.svg" alt="Success" />
                       </span>
                       <span class="message-area">
                           <p>Please place the document on a dark, plain background to ensure the best result
                           </p>
                       </span>
                   </div>
                   <div class="suggetion-message">
                       <span class="icon-area">
                           <img src="images/progress-success.svg" alt="Success" />
                       </span>
                       <span class="message-area">
                           <p>All four corners of the document must be visible in the frame</p>
                       </span>
                   </div>
                   <div class="suggetion-message">
                       <span class="icon-area">
                           <img src="images/progress-success.svg" alt="Success" />
                       </span>
                       <span class="message-area">
                           <p>Avoid any glare, shadows or dark spots on the ID document</p>
                       </span>
                   </div>
                   <div class="suggetion-message">
                       <span class="icon-area">
                           <img src="images/progress-success.svg" alt="Success" />
                       </span>
                       <span class="message-area">
                           <p>Hold the device steady while image is being captured</p>
                       </span>
                   </div>
                   <div class="bottom-button-area">
                       <button class="btn btn-warning"  onClick={() => this.gotoSelectDocument()}>Continue</button>
                   </div>
               </div>
               </div>
              } */}
      {this.state.isEnableDocumentsTabs === true &&
         <Instructions documentType = {this.state.isSelectDocument} ref={this.child} handleLoader={this.handleLoaderStatus} handleRetryLoader={this.handleRetryLoaderStatus} handleChnage= {this.handleChange} fromWhere={'handoff'}/>
      }  

      </div>
      </div>
      </>
      )
      }else if(localStorage.getItem('applicationStatus')==='decliened'){
        setTimeout(() => {
          this.redirectToPurchaseURLAfterTimeout()
        }, 5000);
        return(
          <div class="appfrom-section">
                        <div class="mit-container">
                            <div class="complete-section">
                                <div class="logo-area">
                                    <img src="images/humm-logo.svg" alt="Humm" />
                                </div>
                                {localStorage.getItem('finalMessage')!=null && localStorage.getItem('finalMessage')!='' &&
                                <div class="complete-content">
                                {parse(localStorage.getItem('finalMessage'))}
                                </div>
                                }

                              {buttonLabel &&
                                <button class="btn btn-warning btn-outline mt-3" onClick={this.redirectToPurchaseURLIfExists}>
                                    {buttonLabel}
                                </button>
                              }
                  </div>
              </div>
          </div>
        );
        
      }else if(localStorage.getItem('applicationStatus')==='success'){
        setTimeout(() => {
          this.redirectToPurchaseURLAfterTimeout()
        }, 5000);
        return(
          <div class="appfrom-section">
                        <div class="mit-container">
                            <div class="complete-section">
                                <div class="logo-area">
                                    <img src="images/humm-logo.svg" alt="Humm" />
                                </div>
                                {/* <div class="complete-content">
                                    <h4>Complete</h4>
                                    <p>Thank you, your document verification is in process. </p>
                                </div> */}
                                <div class="complete-content">
                                    <h4>Approved</h4>
                                    {localStorage.getItem('finalMessage')!=null && localStorage.getItem('finalMessage')!='' &&
                                    <p>{parse(localStorage.getItem('finalMessage'))}</p>
                                    }
                                    {buttonLabel &&
                                      <button class="btn btn-warning btn-outline mt-3" onClick={this.redirectToPurchaseURLIfExists}>
                                          {buttonLabel }
                                      </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
        )
      } else if(localStorage.getItem('applicationStatus')==='refer'){
        setTimeout(() => {
          this.redirectToPurchaseURLAfterTimeout()
        }, 5000);
        return(
          <div class="appfrom-section">
                        <div class="mit-container">
                            <div class="complete-section">
                                <div class="logo-area">
                                    <img src="images/humm-logo.svg" alt="Humm" />
                                </div>
                                <div class="complete-content">
                                    <h4>Application Under Review</h4>
                                    {localStorage.getItem('finalMessage')!=null && localStorage.getItem('finalMessage')!='' &&
                                    <p>{parse(localStorage.getItem('finalMessage'))}</p>
                                    }

                                    {buttonLabel && 
                                      <button class="btn btn-warning btn-outline mt-3" onClick={this.redirectToPurchaseURLIfExists}>
                                          {buttonLabel }
                                      </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
        )
      }else{
        return(
          <div class="appfrom-section">
                        <p></p>
                    </div>
        )
      }
        
      }

}

DocumentVerifyMobile.propTypes = {
  history: PropTypes.object,
  handleRetryLoader: PropTypes.bool
}

export default withRouter(DocumentVerifyMobile);