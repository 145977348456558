import React, { Component } from "react";
import { PropTypes } from 'prop-types';
import { withRouter } from "react-router-dom";

export class OpenBankingConsent extends Component {
    constructor(props) {
        console.log('child',props);
        super(props);
        this.state = {
            itemValue: '',
            howDoesWork:"",
            preferNotUse:""
        }
    }
  

    render() {        
        return (
            <div className="openbanking-main">

      <div className="row">
        <div className="col-lg-12 d-flex justify-content-center align-items-center title-section">
          <h2>Open Banking</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 w-100 highlights-header">
          <div className="container">
            <div className="row">
              <div className="col-2 col-lg-2 adv-column d-flex align-items-center justify-content-center gap-5">
                <img src="images/icon-safe.png" alt="safe"/>
                <h4>Safe</h4>
              </div>
              <div className="col-2 col-lg-3 d-flex align-items-center justify-content-center ">
                <span className="seperator-line"></span>
              </div>
              <div className="col-3 col-lg-2 adv-column d-flex align-items-center justify-content-center gap-5">
                <img src="images/icon-secure.png" alt="secure"/>
                <h4>Secure</h4>
              </div>
              <div className="col-2 col-lg-3 d-flex align-items-center justify-content-center ">
                <span className="seperator-line"></span>
              </div>
              <div className="col-3 col-lg-2 adv-column d-flex align-items-center justify-content-center gap-5">
                <img src="images/icon-fast.png" alt="fast"/>
                <h4>Quick</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 ob-desc-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ob-description">
                <p>We just need some additional information to process your application. humm uses technology via Bud to <br/>provide the quickest, simplest, and most secure way to share this information. It's just like sharing copies of<br/> your bank statements.</p>
              </div>
              <div className="col-lg-12 mobile-ob-description">
                <p>We just need some additional information to process your application.</p>
                <p>humm uses technology via Bud to provide the quickest, simplest, and most secure way to share this information. It's just like sharing copies of your bank statements.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 ob-cta-section d-flex justify-content-center">
                <div className="ob-cta-inner-section">
                  <span className="ob-cta-legend-title">Recommended</span>
                  <div className="ob-cta-title d-flex justify-content-center gap-2">
                    <img src="images/icon-flash.png" alt="OB"/>
                    <h4>Open Banking</h4>
                  </div>
                  <div className="ob-cta-desc">
                    <p className="text-center">Bud will securely transfer you to your<br />bank who will prompt you to log in. </p>
                  </div>
                  <div className="mobile-ob-cta-desc">
                    <p className="text-center">Bud will securely transfer you to your bank who <br/> will prompt you to log in. </p>
                  </div>
                  <div className="ob-cta-button d-flex justify-content-center">
                  <button className="btn btn-warning continue-banking" onClick={(e) => {this.props.redirectToOpenBanking()}}>Continue with Open Banking</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="accordion" id="accordionHowWorks">
                <div className="accordion-item rounded-3 border-0 shadow mb-2 howdoeswork">
                  <h2 className="accordion-header">
                    <button className={`accordion-button ${this.state.howDoesWork == "show" ? "" : "collapsed"} fw-semibold  ob-howworks-section d-flex justify-content-start gap-3`} type="button" data-bs-toggle="collapse" data-bs-target="#acc-collapseOne" aria-expanded="false" aria-controls="acc-collapseOne" onClick={()=>{ this.setState({howDoesWork: this.state.howDoesWork == "show" ? "" : "show"})}}>
                      <img src="images/icon-how-works.png" alt="how"/>
                      <h4>How does it work?</h4>
                    </button>
                  </h2>
                  <div id="acc-collapseOne" className={`accordion-collapse collapse ${this.state.howDoesWork}`} data-bs-parent="#accordionHowWorks">
                    <div className="accordion-body">
                      <p>humm uses Bud analysis and reports to support your loan application.</p> 
                      <p>Bud works with Open Banking technology which is backed by the government of all UK and Irish Banks.</p> 
                      <p>With Open Banking you can securely share your account history directly from your banks. It’s just like sharing copies of your bank statements, but easier, faster and safer. There’s no impact on you credit file.</p> 
                      <p>We process hundreds of reports a day and most are completed in less than 3 minutes.</p>
                    </div>
                    
                  </div>
                </div>
              </div>  


            </div>
            <div className="row">
              <div className="col-lg-12 ob-nottouse-section">                
                <div className="accordion" id="accordionOBNotToUse">
                  <div className="accordion-item rounded-3 border-0 shadow mb-2 howdoes">
                    <h2 className="accordion-header prefer-not-use">
                      <button className="accordion-button collapsed fw-semibold  ob-nottouse-head d-flex justify-content-start gap-3" type="button" data-bs-toggle="collapse" data-bs-target="#acc-collapseTwo" aria-expanded="false" aria-controls="acc-collapseTwo" onClick={()=>{ this.setState({preferNotUse: this.state.preferNotUse == "show" ? "" : "show"})}}>
                        <h4>I prefer not to use open banking</h4>
                      </button>
                      {this.state.preferNotUse == "show" ? <button className="accordion-plusminus-button" onClick={(e)=>{ e.preventDefault();this.setState({preferNotUse: this.state.preferNotUse == "show" ? "" : "show"})}}><img src="images\iocn-accordion-minus.svg" alt="img"></img></button> : <button className="accordion-plusminus-button" onClick={(e)=>{ e.preventDefault();this.setState({preferNotUse: this.state.preferNotUse == "show" ? "" : "show"})}}><img src="images\iocn-accordion-plus.svg" alt="img"></img></button>}
                      
                    </h2>
                    
                    <div id="acc-collapseTwo" className={`accordion-collapse collapse ${this.state.preferNotUse}`} data-bs-parent="#accordionOBNotToUse">
                      <div className="accordion-body ob-nottouse-content">
                        <p>This means your application will go to be reviewed manually by an underwriter who may need to request further documents manually via email. This option will delay a decision compared to Open Banking.</p>
                      </div>
                    </div>
                  </div>
                </div> 
                {this.state.preferNotUse== "show" && 
                <div className="ob-nottouse-button d-flex justify-content-center align-items-center ">
                <button className="btn btn-outline-warning without-btn" onClick={(e) => {this.props.redirectToMitekPage()}}>Continue without</button>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
        );
    }   
}
OpenBankingConsent.propTypes = {
  redirectToOpenBanking: PropTypes.func,
  redirectToMitekPage: PropTypes.func
}

export default withRouter(OpenBankingConsent);