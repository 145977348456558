import React from 'react';
import {removeFavoritePartner} from '../../action/partner/removeFavoritePartner';
import {addViewPartner} from '../../action/partner/addViewPartners';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { Link } from 'react-router-dom';
import { thirdPartyWebsiteAlertPopupText } from '../../utils/const';
import { PropTypes } from 'prop-types';

class FavoritePartnerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            value:'',
            isFavAdd:'',
            isFavRemove:'',
            itemList:[],
            partnerID: ''
        }
        this.handleFavoriteRemove = this.handleFavoriteRemove.bind(this)
    }
    componentDidMount(){
        this.setState({itemList:this.props.itemList});
    }

    favoritePartnerChange=(favList)=>{
        this.setState({itemList:favList});
    }

    async handleFavoriteRemove(value,partnerName){
        let  splitPartnerName = partnerName;
        if(partnerName!=undefined){
             splitPartnerName = partnerName.split(' ').join('_');
        }
        
        analyticsClickEvent('favorite_removed',splitPartnerName);
        const removeDataParam={email:localStorage.getItem('email'),account:value};
        await removeFavoritePartner (removeDataParam);
        let currentState = this.state.itemList;
        let removeFavUpdated = currentState.filter(function( obj ) {
            return obj.partnerId !== value;
        });
        await this.setState({itemList:removeFavUpdated});
        this.props.checkFavouriteExists();
     }
   
     addViewedPartner=async(partnerID,partnerWebsitLink)=>{
        if(partnerWebsitLink == '' || partnerWebsitLink == null || partnerWebsitLink == undefined){
            return false;
        }

        const resultPartnerURL = this.checkURL(partnerWebsitLink);
        this.setState({partnerID: partnerID}, () => {
          localStorage.setItem("partnerWebsiteURL", resultPartnerURL);
          setTimeout(() => {
            window.$('#thirdPartyWebsiteModel').modal('show');
          }, 200);
        });
     }

     checkURL=(url)=>{
        if(url!=null && url!=undefined && url!=''){
           let pattern = /^((http|https|ftp):\/\/)/;

           if(!pattern.test(url)) {
            return "http://" + url;
           }else{
            return url;
           }
        }else{
           return url 
        }
       
    }

    redirectionThirdPartnerSite = async () => {
        if(localStorage.getItem('fromWhere') != 'apply_now') {
          const requestObject = {
            email: localStorage.getItem('email'),
            account: this.state.partnerID
          }
          await addViewPartner(requestObject);
        }
            
        if(localStorage.getItem("partnerWebsiteURL")) {
          window.open(localStorage.getItem("partnerWebsiteURL"), '_blank', 'noopener noreferrer');
          window.$('#thirdPartyWebsiteModel').modal('hide');
        }
    
    }

    render() { 
        
        return (  
            <>
            <div className="gallery-partner">
            { this.state.itemList?.map(response =>  <div className="featured-box partner-item"   key={response.partnerId} >               
            <div id={response.partnerId} className="featured-itemQr">
            <div className="favorite-section">
                <a target rel="noreferrer" href="javascript:void(0)" onClick={()=> {this.addViewedPartner(response.partnerId,response.partnerWebSite).catch(error => console.error(error)) }}>
                    <div className="partner-img">
                    {response.partnerCoverImage==undefined &&
                      <img src="images/compu.png" alt={response.partnerName} />
                }
                {response.partnerCoverImage!=undefined &&
                      <img src={response.partnerCoverImage} alt={response.partnerName} 
                      onError={(evt) => evt.target.src = "images/partner-placeholder.png"} />
                }
                        <div className="overlay">
                        </div>
                    </div>
                </a>
                <div className="fav-icon-section">
                {this.state.inputType &&(<span className="view-icon" onKeyDown={(e) => { e.preventDefault(); }}  onClick={this.inputChangeHandler}> <i className="fa fa-eye"></i> </span>)}
                               
                    {response.partnerFavId===null &&(<a value={response.partnerId} onKeyDown={(e) => { e.preventDefault(); }} onClick={e => this.handleFavoriteAdd(response.partnerId)} className='fav-icon-btn'><img src="images/heart-white.svg" alt="Fav" /></a>) }
                    {response.partnerFavId!==null &&(<a value={response.partnerId} onKeyDown={(e) => { e.preventDefault(); }} onClick={e => { this.handleFavoriteRemove(response.partnerId,response.partnerName).catch(error => console.error(error)) }} className='fav-icon-btn active'><img src="images/heart.svg" alt="Fav-active" /></a>) }
                    
                    
                </div>  
                <a target rel="noreferrer" href="javascript:void(0)" onClick={()=> { this.addViewedPartner(response.partnerId,response.partnerWebSite).catch(error => console.error(error)) }}>                      
                    <div className="overlay-logo-item">
                        <span className="featured-caption">
                            <div className="slider-logo-image">
                                {response.partnerLogo != null &&
                                   <img src={response.partnerLogo} alt={response.partnerName}/>
                                }
                                {response.partnerLogo == null &&
                                    <h6 >{response.partnerName}</h6>
                                }
                            </div>
                        </span>
                    </div>
                </a>
                <div className="qtwo-calculator">
                    <Link to={{
                        pathname: "/quote-calculator",
                        state: { id: response.partnerId, store_Name: response.partnerName }

                    }} onClick={localStorage.setItem('prevRoute',window.location.pathname)}>
                        <button className="qc-click-btn"
                        onClick={(_e) => { analyticsClickEvent('partner_quote_calculator', '') }}
                        >                            
                             <span className=''>
                                Get A Quote                                
                            </span>
                            <img src="images/right-arrow-shop.svg" width={25} alt="icon" />
                        </button></Link>

                </div>
            </div>
            </div>
        </div>) 
        } 
        </div>
        <div className="modal model-error fade" id="thirdPartyWebsiteModel" data-keyboard="false" tabIndex="-1" aria-labelledby="thirdPartyWebsitePopupLabel">
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-header">
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img className="img-fluid" alt="logo" src="images/modal-logo.svg" />
                </div>

                <p className="mt-4 text-center mb-0">{thirdPartyWebsiteAlertPopupText}</p>
              </div>

              <div className="modal-footer mb-4">
                <button type="button" className="btn-warning btn btn-outline" data-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={() => {
                    this.redirectionThirdPartnerSite().catch(error => console.error(error)) }} >Ok</button>
              </div>


            </div>
          </div>
        </div>
        </>
        
        );
    }
}

FavoritePartnerList.propTypes = {
    itemList: PropTypes.object,
    checkFavouriteExists: PropTypes.func,
}
 
export default FavoritePartnerList;