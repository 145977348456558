import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const CreateSetupIntent = async (formData) => {
    let url = config.hummBackendAppURL+'wallet-management/Create-SetupIntent'

   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};

export const CreatePaymentIntent = async (formData) => {
    let url = config.hummBackendAppURL+'wallet-management/Customer-Payment'
   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};

export const ConfirmPaymentIntent = async (formData) => {
    let url = config.hummBackendAppURL+'wallet-management/Confirm-PaymentIntent'

   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};

export const CreateStipeCustomer = async (formData) => {
    let url = config.hummBackendAppURL+'wallet-management/Create-Stripe-Customer'

   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};

export const GetStipeCustomerData = async (formData) => {
    let url = config.hummBackendAppURL+'wallet-management/Get-Stripe-Customer'

   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};


export const AttachPaymentMethod = async (formData) => {
    let url = config.hummBackendAppURL+'wallet-management/Attach-PaymentMethod'

   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};

export const DeAttachPayment = async (formData) => {
    let url = config.hummBackendAppURL+'wallet-management/De-attach-PaymentMethod'

   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};





