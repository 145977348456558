import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


export const getIndustriesList = () => {

    let url = config.hummBackendAppURL+'partner/Industry-list'
    return async (dispatch) => {
        let resp = [];
         await axios.get(url,{headers:sessionID()})
            .then(response => {
                dispatch({
                    type: 'INDUSTRIES_LIST',
                    value: response.data
                });
                resp = response;
                return resp;
            })
            .catch(async error => {
                await userLogs(url,'',error);
                if(error.response){
        handlingSession(error.response.status);
        }
                resp = error.response;
                return resp;
            })
            return resp;
    }
};
