import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


export const getApplicationStatus = async (formData) => {
    let url = config.hummBackendAppURL+'application/Check_approve_status';
   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};

