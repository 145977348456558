import { getApplicationImage } from "../action/avatar/getAvatar";


const getApplicationBannerImg = async() => {
    if(localStorage.getItem('fromWhere') !='skip'){
        const applicationImg = await getApplicationImage();
        if (applicationImg && applicationImg.status === 201 && Array.isArray(applicationImg.data.image) && applicationImg.data.image.length !== 0 && applicationImg.data.image[1] != undefined) {
                let appImg = applicationImg.data.image[1];
                localStorage.setItem('applicationImage', appImg);                                   
        }
        else {
            localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
        }
    }
}

export default getApplicationBannerImg;