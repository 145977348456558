import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from '../../utils/handlingSession'
import {userLogs} from '../logs/userLogs'

export const generateSessionForUser = async (formData) => {
    let url = config.hummBackendAppURL+'user/generate_session';
   return axios.post(url, formData,{headers:sessionID()}).then(generateSessionResponse => {
        return generateSessionResponse;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })

};