import React from 'react';
import { PropTypes } from 'prop-types';

export class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        let {obStatusRecived} = this.props
        return ( 
            <div className={`wrapper welcome-page loader-page ${this.props.loaderStatus?'':'custom-hide'}`} >
            
            <div className="logo">
                <img src="images/loading-logo-img.svg" className="img-fluid" alt="logo"/>
            </div>
            <div className="loading-dots">
                <div className="snippet" data-title=".dot-flashing">
                  <div className="stage">
                    <div className="dot-flashing"></div>
                  </div>
                </div>
              </div>
              {obStatusRecived &&
              <div>
                <p className='obloader'>We're processing your application.<br/>
                This can take up to 2 minutes.<br/>
                Please do not close your screen.</p>
              </div>
    }
        </div>
         );
    }
}

Loader.propTypes = {
  obStatusRecived: PropTypes.bool
}
 
export default Loader;