import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


export const getFavoritePartner = (formData) => {

    let url = config.hummBackendAppURL+'favorite-partner/list'
    return async (dispatch) => {
        let favoritePartnerResponse=[];
        await axios.post(url,  formData,{headers:sessionID()} )
            .then(response => {
                dispatch({
                    type: 'FAVOURITE_PARTNER',
                    value: response.data
                });
                favoritePartnerResponse= response;
                return favoritePartnerResponse;
            })
            .catch(async error => {
                await userLogs(url,formData,error);
                if(error.response){
        handlingSession(error.response.status);
        }
                favoritePartnerResponse = error.response;
                return favoritePartnerResponse;
            })
            return favoritePartnerResponse;
    }
};

