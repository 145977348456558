import { frontEndLogs } from "./frontEndLogs";

export const handleRefreshTheScreen= (screen) => {
    const performanceEntries = performance.getEntriesByType('navigation');
    console.log('performanceEntries',performanceEntries,screen);
    if (performanceEntries.length && performanceEntries[0].type === 'reload') {
      frontEndLogs("The "+screen+" page is reloaded - "+localStorage.getItem('applicationId'),false,200,'handleRefreshTheScreen');
    }
}

export const handlePopstate= () => {
    frontEndLogs("On the browser back or forward button was clicked - "+localStorage.getItem('applicationId'),false,200,'handlePopstate');
}