import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession';
import {userLogs} from './../logs/userLogs'

export const resetPasswords = async (formData) => {
    let url = config.hummBackendAppURL+'user/password-reset'

   return axios.put(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
            handlingSession(err.response.status);
        }
        return err.response
    })


};