import axios from 'axios';
import config from '../config';
import {sessionID} from '../../utils/handlingSession';
import Cookies from 'universal-cookie';
export const frontEndLogs = async (logDescription,critical,statusCode,methodName, error_message="") => {

     const cookie = new Cookies();
     let url = config.hummBackendAppURL+'user-logs/logs';
     let date =  new Date().toJSON().replace("T",' ').replace("Z",'+00');
     let user = (localStorage.getItem('fromWhere') === 'apply_now') ? 'apply_now': 'login';


     let obj = {
        "userId":sessionID().session_id, 
        "log_description":  logDescription,
        "logtypeLogTypeId": "", 
        "status_code": statusCode,
        "critical":   critical,
        "class_name": "",  
        "module_name": window.location.href,
        "method_name": methodName, 
        "line_number": "", 
        "device_id": "",
        "session_id": sessionID().session_id, 
        "login_type": user,
        "ip_address": "", 
        "created_date": date,
        "build_number": "",
        "device_type": "web",            
        "api_endpoint": "",
        "error_message":error_message,
        "request_payload": "",
        "customer_email": localStorage.getItem('email'),
        "application_id": localStorage.getItem('applicationId') ? localStorage.getItem('applicationId') :cookie.get('applicationId'),
        "user_tracking_id": localStorage.getItem('user_tracking_id')
    }
    return await axios.post(url,obj,{headers:sessionID()}).then(res => {
        return res
    })
    .catch(err => {
        return err.response
    })


};