import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from '../../utils/handlingSession'
import {userLogs} from '../logs/userLogs'

export const sendMobileOTP = async (formData) => {
    let url = config.hummBackendAppURL+'user/send_mobile_otp';
   return axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })

};

export const verifyMobileOTP = async (formData) => {
    let url = config.hummBackendAppURL+'user/verify_mobile_otp';
   return axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })

};

