import { createReducer } from '../store.js';
const propic = localStorage.getItem('profilePicture');

export default createReducer(
	{
		assetList: null,
        FavouritePartners: null,
		ViewPartners:null,
		FireBaseStore:null,
		loginStore:null,
		manualRegisterStore:null,
        profilePicture:propic,
        viewedPartnerStore:null,
		viewedPartnerSearchStore:null,
		favouritePartnerStore:null,
		favouritePartnerSearchStore:null,
		allpartnerListStore:null,
		allpartnerListSearchStore:null,
		allCategoryListStore: null,
        allCategoryListSearchStore: null,
		allIndustriesListStore:null,
		getApplicationQuestions:null,
		getPersonalQuestions: null,
		movenext:null,
		applicationValidation:[],
		showAppValidations:'',
		emails:'',
		confirmemail:'',
		saveApplicationData:'',
		phoneNumbers:'',
		saveDates:'',
		saveDatesMovedIn:'',
		amountRequested:'',
		saveexpendituredata:'',
		employmentStatus: false,
		contractSummary:'',
		threashold:'',
		prepopulateStatus:false,
		userDetailsPopulateStatus: false,
		birthDateFlag: false,
		firstNameFlag: false,
		lastNameFlag: false,
		emailFlag: false, 
		mobileNumberFlag: false,
		questionValueSave:[],
		notificationDatas:[],
		storePartnerImage:[],
		showCurrentAddress:'',
		userId: '',
		customPathname: '',
		resultStoreName: '',
		firstSectionSteps: [],
		loaderStatus: false,
		secondSectionSteps: [],
		thirdSectionSteps: [],
		fourSectionSteps: [],
		sectionHeading: [],
		sectionSubContext: [],
		DocumentAgreeLabel: '',
		AgreeLabel: '',
		contactDocumentAgree: '',
		isFrequencyExists: false,
		isMobileVerified: false,
		isEmailVerified: false,
		isChangeMobile: false,
		isChangeEmail: false,
		saveTermData: '',
		saveIncomeData: '',
		isDisableChangeMobileOption: false,
		isDisableChangeEmailOption: false
	},
	{
		'ASSET_LIST': (state, action) => ({
			...state,
			assetList: action.value
		}),
		'USER_ID': (state, action) => ({
			...state,
			userId: action.value
		}),
		'SET_CUSTOM_PATH': (state, action) => ({
			...state,
			customPathname: action.value
		}),
		'SET_STORE_NAME': (state, action) => ({
			...state,
			resultStoreName: action.value
		}),
		'UNSET_STORE_NAME': (state, action) => ({
			...state, 
			resultStoreName: null
		}),
        'FAV_PARTNERS': (state, action) => ({
			...state, 
			FavouritePartners: action.value
		}),
		'VIEW_PARTNERS': (state, action) => ({
			...state, 
			ViewPartners: action.value
		}),
		'FIRE_BASE': (state, action) => ({
			...state,
				FireBaseStore: action.value
			}),
		'LOGIN_STORE': (state, action) => ({
			...state, 
			loginStore: action.value
		}),
		'REGISTER_STORE': (state, action) => ({
			...state, 
			manualRegisterStore: action.value
		}),
    	'VIEWED_PARTNER': (state, action) => ({
			...state,
			viewedPartnerStore: action.value
		}),
		'VIEWED_SEARCH_PARTNER': (state, action) => ({
			...state, 
			viewedPartnerSearchStore: action.value
		}),
		'FAVOURITE_SEARCH_PARTNER': (state, action) => ({
			...state,
				favouritePartnerSearchStore: action.value
			}),
		'FAVOURITE_PARTNER': (state, action) =>({
			...state,
			favouritePartnerStore: action.value
		}),
		'PARTNER_LIST': (state, action) => ({
			...state, 
				allpartnerListStore: action.value
			}),
		'PARTNER_SEARCH_LIST': (state, action) => ({
			...state, 
				allpartnerListSearchStore: action.value
			}),
		'CATEGORY_LIST': (state, action) => ({
			... state,
			allCategoryListStore: action.value
			}),
		'CATEGORY_SEARCH_LIST': (state, action) => ({
			...state, 
				allCategoryListSearchStore: action.value
			}),
		'SET_PROFILE_PIC': (state, action) => {
			localStorage.setItem('profilePicture',action.value)
			return { ...state,
					profilePicture: action.value
			}
		},
		'UNSET_PROFILE_PIC': (state, action) => {
			localStorage.removeItem('profilePicture');
			return { ...state,
				profilePicture: null
			}
		},
		'INDUSTRIES_LIST': (state, action) => ({
			...state, 
			allIndustriesListStore: action.value
		}),
		'APPLICATION_QUESTIONS': (state, action) => ({
			...state, 
			getApplicationQuestions: action.value
			}),
		'PERSONAL_QUESTIONS': (state, action) => ({
			...state,
			getApplicationQuestions: action.value
		}),
		'NEXT_MOVE': (state,action) => ({
			...state,
				movenext: action
			}),
		'APP_VALIDATIONS': (state,action) => {
			return { 
				...state,
				applicationValidation: [...state.applicationValidation, action.value]
			}
		},
		'APP_VALIDATIONS_Remove': (state,action) => ({
			...state,
			applicationValidation: action.value
			}),
		'SHOW_APP_VALIDATIONS': (state,action) => ({
			...state,
				showAppValidations: action.value
			}),
		'HIDE_APP_VALIDATIONS': (state,action) => ({
			...state,
			showAppValidations: action.value
		}),
		'EMAIL': (state,action) => ({
			...state,
				emails: action.value
			}),
		'FIRST_SECTION_STEPS': (state,action) => {
			return { 
				...state,
				firstSectionSteps: [...state.firstSectionSteps, action.value]
			}
		},
		'FIRST_SECTION_STEPS_REMOVE': (state,action) => ({
			...state,
			firstSectionSteps: action.value
		}),
		'THIRD_SECTION_STEPS': (state,action) => {
			if(state.thirdSectionSteps!==''){
				state.thirdSectionSteps.map((src,key)=>{
					if(Object.keys(action.value)['0']===Object.keys(src)['0']){
					   state.thirdSectionSteps.splice(key, 1);
					}
				})
			}
			return { 
				...state,
				thirdSectionSteps: [...state.thirdSectionSteps, action.value]
			}
		},
		'THIRD_SECTION_STEPS_REMOVE': (state,action) => ({
			...state,
			thirdSectionSteps: action.value
		}),
		'CONFIRMEMAIL': (state,action) => ({
			...state,
				confirmemail: action.value
		}),
		'FOUR_SECTION_STEPS': (state,action) => {
			return { 
				...state,
				fourSectionSteps: [...state.fourSectionSteps, action.value]
			}
		},
		'FOUR_SECTION_STEPS_REMOVE': (state,action) => ({
			...state,
			fourSectionSteps: action.value
		}),
		'SECTION_HEADING': (state,action) => {
			if(state.sectionHeading !==''){
				state.sectionHeading.map((src,key)=>{
					if(Object.keys(action.value)['0']===Object.keys(src)['0']){
					   state.sectionHeading.splice(key, 1);
					}
				})
			}
			return { 
				...state,
				sectionHeading: [...state.sectionHeading, action.value]
			}
		},
		'SECTION_HEADING_REMOVE': (state,action) => ({
			...state,
			sectionHeading: action.value
		}),
		'SECTION_CONTEXT': (state,action) => {
			if(state.sectionSubContext !==''){
				state.sectionSubContext.map((src,key)=>{
					if(Object.keys(action.value)['0']===Object.keys(src)['0']){
					   state.sectionSubContext.splice(key, 1);
					}
				})
			}
			return { 
				...state,
				sectionSubContext: [...state.sectionSubContext, action.value]
			}
		},
		'SECTION_CONTEXT_REMOVE': (state,action) => ({
			...state,
			sectionSubContext: action.value
		}),
		'DOCUMENT_AGREE_LABEL': (state,action) => ({
			...state,
			DocumentAgreeLabel: action.value
		}),
		'AGREE_LABEL': (state,action) => ({
			...state,
				AgreeLabel: action.value
		}),
		'CONTACT_AGREE_LABEL': (state,action) => ({
			...state,
			contactDocumentAgree: action.value
			}),
		'REMOVE_CONTACT_AGREE_LABEL': (state,action) => ({
			...state,
			contactDocumentAgree: action.value
		}),
		'REMOVE_DOCUMENT_AGREE_LABEL': (state,action) => ({
			...state,
			DocumentAgreeLabel: action.value
		}),
		'REMOVE_AGREE_LABEL': (state,action) => ({
			...state,
			AgreeLabel: action.value
		}),
		'SET_MOBILE_CHANGE': (state,action) => ({
			...state,
			isChangeMobile: action.value
		}),
		'RESET_MOBILE_CHANGE': (state,action) => ({
			...state,
			isChangeMobile: action.value
		}),
		'SET_EMAIL_CHANGE': (state,action) => ({
			...state,
			isChangeEmail: action.value
		}),
		'RESET_EMAIL_CHANGE': (state,action) => ({
			...state,
			isChangeEmail: false
		}),
		'SET_MOBILE_VERIFIED': (state,action) => ({
			...state,
			isMobileVerified: action.value
		}),
		'RESET_MOBILE_VERIFIED': (state,action) => ({
			...state,
			isMobileVerified: false
		}),
		'SET_EMAIL_VERIFIED': (state,action) => ({
			...state,
			isEmailVerified: action.value
		}),
		'RESET_EMAIL_VERIFIED': (state,action) => ({
			...state,
			isEmailVerified: false
		}),
		'SET_FREQUENCY_EXISTS': (state,action) => ({
			...state,
			isFrequencyExists: action.value
		}),
		'RESET_FREQUENCY_EXISTS': (state,action) => ({
			...state,
			isFrequencyExists: action.value
		}),

		'SECOND_SECTION_STEPS': (state,action) => {
			if(state.secondSectionSteps!==''){
				state.secondSectionSteps.map((src,key)=>{
					if(Object.keys(action.value)['0']===Object.keys(src)['0']){
					   state.secondSectionSteps.splice(key, 1);
					}
				})
			}
			return { 
				...state,
				secondSectionSteps: [...state.secondSectionSteps, action.value]
			}
		},
		'SECOND_SECTION_STEPS_REMOVE': (state,action) => ({
			...state,
			secondSectionSteps: action.value
		}),
		'SAVEDATA': (state,action) => {
		 if(state.saveApplicationData!==''){
			state.saveApplicationData.map((src,key)=>{
				if(Object.keys(action.value)['0']===Object.keys(src)['0']){
				   state.saveApplicationData.splice(key, 1);
				}
			})
			}
		   return { 
			   ...state,
			   saveApplicationData: [...state.saveApplicationData,action.value]
		   }
	   },
	   'REMOVEDATA': (state,action) => ({
			...state,
			saveApplicationData: action.value
		   }),
		'PREPOPOLATE': (state,action) => {
			return {
				...state,
				saveApplicationData: [...state.saveApplicationData,action.value]
			}
		 },
		'PHONENO': (state,action) => ({
			...state,
				phoneNumbers: action.value
			}),
		'SAVEDATE': (state,action) => ({
			...state,
				saveDates: action.value
			}),
		'SAVEDATEMOVEDIN': (state,action) => ({
			...state,
			saveDatesMovedIn: action.value
			}),
		'NOTIFICATIONDATA': (state,action) => ({
			...state,
			notificationDatas: action.value
			}),
		'AMOUNTREQUESTED': (state,action) => ({
			...state,
				amountRequested: action.value
		}),
		'SAVEEXPENDITUREDATA': (state,action) => ({
			...state,
				saveexpendituredata: action.value
		}),
		'SAVETERMDATA': (state,action) => ({
			...state,
				saveTermData: action.value
		}),
		'SAVEINCOMEDATA': (state,action) => ({
			...state,
				saveIncomeData: action.value
		}),
		'EMPLOYNMENTSTATUS': (state,action) => ({
			...state,
			employmentStatus: action.value
			}),
		'SET_CONTRACT_SUMMARY':(state,action) => {
			localStorage.setItem('contractSummary',JSON.stringify(action.value));
			return {...state,
				contractSummary: action.value
			}
		},
		'UNSET_CONTRACT_SUMMARY':(state,action) => {
			localStorage.removeItem('contractSummary');
			return {... state,
				contractSummary: ''
			}
		},
		'THREADSHOLD': (state,action) => ({
			...state,
			threashold: action.value
			}),
		'PREPOPULATEE': (state,action) => ({
			...state,
				prepopulateStatus: action.value
		}),
		'USER_DETAILS_PREPOPULATE': (state,action) => ({
			...state,
			userDetailsPopulateStatus: action.value
		}),
		'BIRTH_DATE_FLAG': (state,action) => ({
			...state,
			birthDateFlag: action.value
		}),
		'FIRST_NAME_FLAG': (state,action) => ({
			...state,
			firstNameFlag: action.value
		}),
		'LAST_NAME_FLAG': (state,action) => ({
			...state,
			lastNameFlag: action.value
		}),
		'EMAIL_FLAG': (state,action) => ({
			...state,
			emailFlag: action.value
		}),
		'MOBILE_NUMER_FLAG': (state,action) => ({
			...state,
			mobileNumberFlag: action.value
		}),
		'QUESTIONSAVE':(state,action) => {
			if(state.questionValueSave!==''){
				state.questionValueSave.map((src,key)=>{
					if(action.value['field_api_name']===src['field_api_name']){
					   state.questionValueSave.splice(key, 1);
					}
				})
				}
			return {
				...state,
				questionValueSave: [...state.questionValueSave,action.value]
			}
		},
		'REMOVEQUESTIONSAVE': (state,action) => ({
			...state,
			questionValueSave: action.value
		}),
		'STOREPARTNERIMAGE' :(state,action)=>{
			if(state.storePartnerImage!==''){
				state.storePartnerImage.map((src,key)=>{
					if(action.value['image_url']===src['image_url'] && action.value['access_token']===src['access_token']){
					   state.storePartnerImage.splice(key, 1);
					}  
					})
				}
			return {
				...state,
				storePartnerImage: [...state.storePartnerImage,action.value]
			}
		},
		'SHOWADDRESS': (state,action) => ({
			...state,
			showCurrentAddress: action.value
		}),
		'UPDATE_MOBILE_CHANGE_OPTION': (state,action) => ({
			...state,
			isDisableChangeMobileOption: action.value
		}),
		'UPDATE_EMAIL_CHANGE_OPTION': (state,action) => ({
			...state,
			isDisableChangeEmailOption: action.value
		}),
	}
);
