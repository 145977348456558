
import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


export const getCategorieSearch = (formData) => {

    let url = config.hummBackendAppURL+'industry/Industry-filter'

    return async (dispatch) => {
        let categorieSearchResponse = [];
        await axios.post(url,formData,{headers:sessionID()})
            .then(response => {
                dispatch({
                    type: 'CATEGORY_SEARCH_LIST',
                    value: response.data
                });
                categorieSearchResponse = response;
                return categorieSearchResponse;
            })
            .catch(async error => {
                await userLogs(url,formData,error);
                if(error.response){
        handlingSession(error.response.status);
        }
                categorieSearchResponse = error.response;
                return categorieSearchResponse;
            })
            return categorieSearchResponse;
    }
};