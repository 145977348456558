import React from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { socialLogin }  from "../../../action/auth/socialLogin";
import { Link,Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Twitter } from "../login/twitter";
import { analyticsClickEvent, analyticsLoginSuccessEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { getApplicationImage } from "../../../action/avatar/getAvatar";
import Cookies from "universal-cookie";
import parse from 'html-react-parser';
import { extractEmail } from "../../../utils/extractEmail";
import { PropTypes } from 'prop-types';
import AppleUserLogin from "../login/appleLogin.jsx";

let firebaseConfig = {
    apiKey: process.env.REACT_APP_AUTHAPIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export class SocialMedia extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            email: '',
            password: '',
            disable: '',
            errors: {
                userName: '',
                email: '',
                password: '',
                serverError: '',
            },
            commonError: '',
            serverError: '',
            homePage: '',
            userExists: false,
            redirect : false,
            inputType:false,
            loginMessage:'',
            showErrorMessage: '',
        };
        this.handleFbUserLogin = this.handleFbUserLogin.bind(this);
        this.handleGoogleUserLogin = this.handleGoogleUserLogin.bind(this);
        
        localStorage.setItem('contentFrom',this.props.fromWhere);
    }

    getApplicationBannerImg = async() => {
      if(localStorage.getItem('fromWhere') !='skip'){
          const applicationImg = await getApplicationImage();
          if (applicationImg && applicationImg.status === 201 && Array.isArray(applicationImg.data.image) && applicationImg.data.image.length !== 0 && applicationImg.data.image[1] != undefined) {
                  let appImg = applicationImg.data.image[1];
                  localStorage.setItem('applicationImage', appImg);                                   
          }
          else {
            localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
          }
       }
    }

    async handleGoogleUserLogin() {
        const userGoogleDetails = await this.handleGoogleLogin();
         await this.checkLoginState(userGoogleDetails);
        if (userGoogleDetails) {
          if (userGoogleDetails.email) {
              localStorage.setItem('email', userGoogleDetails.email);
                const obj = {
                    'username': userGoogleDetails.firstname,
                    'email': userGoogleDetails.email,
                    'uu_id': userGoogleDetails.user_id,
                    'provider': 2,
                    'Application_Channel': 'Web',
                    "Build_Version": "Web App"
                }
                this.props.setUserId(userGoogleDetails.user_id)
                localStorage.setItem('SocialType', 2);
                localStorage.setItem('UserName', userGoogleDetails.firstname);

                const googleResult = await this.props.socialLogin(obj);
                let checkGmailSocialLoginResult = this.checkGmailSocialLoginResponse(googleResult);
                if(checkGmailSocialLoginResult === false){
                  return false;
                } 
                
                let checkGmailSocialLoginPhoneValidationResult = this.checkGmailSocialLoginPhoneValidation(googleResult,userGoogleDetails);
                if(checkGmailSocialLoginPhoneValidationResult === false){
                  return false;
                } 
                  this.setState({redirect: true});
                }
        }

    }


    checkGmailSocialLoginResponse = (googleResult) => {
      if(googleResult.data.message==='This email already using with Gmail account so must login with Gmail'
                  || googleResult.data.message==='This email already using with Facebook account so must login with Facebook' ||
                  googleResult.data.message==='This email already using with Twitter account so must login with Twitter'){
                    localStorage.clear();
                    this.setState({loginMessage:googleResult.data.message})
                    window.$('#exampleModal').modal('show');
                    return false;
                }
                if(googleResult.data.message==="This email already using with Humm account so must login with Humm"){
                    window.$('#exampleModal').modal('show');
                   this.setState({loginMessage: googleResult.data.message});
                 }                

                if(googleResult.data.message == "Please enter the verification code we've sent by SMS" && googleResult.data.statusCode === 200){
                    localStorage.setItem('mobileRequired',true);
                    this.props.history.push('/mobile-verification')

                    return false
                }

                 if (googleResult.data.statusCode === 200 || googleResult.data.statusCode === 201) {
                   localStorage.setItem('token', googleResult.data.data.access_token);
                   localStorage.setItem('sessionId',googleResult.data.data.session_id);
                   localStorage.setItem('csrfToken',googleResult.data.data.csrf_token);
                   this.setState({userExists: googleResult.data.data.user_exists});

                  localStorage.setItem('emailConsent', googleResult?.data?.data?.user_details?.marketing_consent?.email_marketing_consent);
                  localStorage.setItem('smsConsent', googleResult?.data?.data?.user_details?.marketing_consent?.sms_marketing_consent);
                  localStorage.setItem('mobileConsent', googleResult?.data?.data?.user_details?.marketing_consent?.mobile_app_marketing_consent);
                   localStorage.setItem('userExists',googleResult.data.data.user_exists);
                   // Set the state whether the user already exists or not
                  analyticsLoginSuccessEvent('login','social_media_login')

                  if(googleResult.data.data.user_details.userid){
                    localStorage.setItem('userID', googleResult.data.data.user_details.userid);
                  }
                  if(!this.state.userExists){
                    this.getApplicationBannerImg();
                  }
                  
                  }
    }


    checkGmailSocialLoginPhoneValidation = (googleResult,userGoogleDetails) => {
      if(googleResult.data.statusCode === 400 && googleResult.data.message == 'Application phone number is required') {
        localStorage.setItem('uu_id', userGoogleDetails.user_id);
        localStorage.setItem('mobileRequired',true);
        this.props.history.push('/mobile-verification')

        return false
      }
      else if(googleResult.data.statusCode == 403){
        let errorMessage = googleResult.data.message;
        if (extractEmail(errorMessage) != null) {
          let extractErrorMessage = extractEmail(errorMessage);
          extractErrorMessage.map((src) => {
            let anchorTag = "<a target='_blank' style='color: #ff6200' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
            errorMessage = errorMessage.replace(src, anchorTag);
            })
        }
        this.setState({showErrorMessage: errorMessage}, () => {
          window.$('#socialMediaErrorModal').modal('show');
        })
        return false;
      }
    }

 
 async handleGoogleLogin() {
  analyticsClickEvent('google_login','')
    let provider = new firebase.auth.OAuthProvider('google.com');
    provider.addScope('profile');
    provider.addScope('email');
 
        
        return firebase.auth().signInWithPopup(provider).then(function (result) {
                let token = result.credential.accessToken;
                let user = result.user;
                let userEmail = result.user.email;
                if(!userEmail){
                    userEmail=result.additionalUserInfo.profile.email;
                }
                return   {
                    firstname: user.displayName,
                    lastname: result.additionalUserInfo.profile.last_name,
                    email:userEmail,
                    user_id: result.additionalUserInfo.profile.id,
                    access_token: token,
                    refresh_token: user.refreshToken,
                    errorEmail: ''
                }

              

            }).catch((error) => {
               
                let errorMessage = error.message;
                if (error.email) {
                    return {email: error.email,firstname: 'test'}
                   
                } else {
                    return {errorEmail: errorMessage}
                }

            });
    }


    async handleFbUserLogin() {
      analyticsClickEvent('facebook_login','')
        const userFbDetails = await this.handleFbLogin();
console.log(userFbDetails);
         await this.checkLoginState(userFbDetails);
        
        if (userFbDetails) {
          if (userFbDetails.email) {
              localStorage.setItem('email', userFbDetails.email);
                const obj = {
                    'username': userFbDetails.firstname,
                    'email': userFbDetails.email,
                    'uu_id': userFbDetails.user_id,
                    'provider': 3,
                    'Application_Channel': 'Web',
                    "Build_Version": "Web App"
                }

                localStorage.setItem('UserName', userFbDetails.firstname);
                // Set the state of SocialType for phoneVerification of social user 
                localStorage.setItem('SocialType', 3);
                this.props.setUserId(userFbDetails.user_id)
                const result = await this.props.socialLogin(obj);
                let validateResult = this.handlePropsSocialLoginResponse(result);
                if(validateResult === false){
                  return false;
                }

                let validatePhoneResult = this.handlePhoneNumberValidation(result);
                if(validatePhoneResult === false){
                  return false;
                }
                this.setState({redirect: true});
            }else if(userFbDetails.errorEmail){
                return false;
            }else if(userFbDetails.email === undefined){
                this.setState({loginMessage:'Please try again with an account which has email id registerd with it'});
                window.$('#exampleModal').modal('show');
                return false;
            }
        }
    }

    handlePropsSocialLoginResponse = (result)=>{
      if(result.data.message==='This email already using with Gmail account so must login with Gmail'
                  || result.data.message==='This email already using with Facebook account so must login with Facebook' ||
                  result.data.message==='This email already using with Twitter account so must login with Twitter'){
                    localStorage.clear();
                    this.setState({loginMessage:result.data.message})
                    window.$('#exampleModal').modal('show');
                    return false;
                }
                if(result.data.message==="This email already using with Humm account so must login with Humm"){
                  this.setState({loginMessage: result.data.message});
                    window.$('#exampleModal').modal('show');
                   return false;
                 }

                localStorage.setItem('userExists',result.data.data.user_exists);
                localStorage.setItem('token', result.data.data.access_token);
                localStorage.setItem('sessionId',result.data.data.session_id);
                localStorage.setItem('csrfToken',result.data.data.csrf_token);
                if(result.data.message == "Please enter the verification code we've sent by SMS" && result.data.statusCode === 200){
                  localStorage.setItem('mobileRequired',true);
                  this.props.history.push('/mobile-verification')

                  return false
              }
                if (result.data.statusCode === 200 || result.data.statusCode === 201) {
                  // Set the state whether the user already exists or not
                 this.setState({userExists: result.data.data.user_exists});
                  analyticsLoginSuccessEvent('login','social_media_login')
                  if(!this.state.userExists){
                    this.getApplicationBannerImg();
                  }
                  localStorage.setItem('emailConsent', result?.data?.data?.user_details?.marketing_consent?.email_marketing_consent);
                  localStorage.setItem('smsConsent', result?.data?.data?.user_details?.marketing_consent?.sms_marketing_consent);
                  localStorage.setItem('mobileConsent', result?.data?.data?.user_details?.marketing_consent?.mobile_app_marketing_consent);

                  if(result.data.data.user_details.userid !== '' && 
                  result.data.data.user_details.userid  != null && result.data.data.user_details.userid  != undefined){
                    localStorage.setItem('userID', result.data.data.user_details.userid);
                  }
                }
    }

    handlePhoneNumberValidation = (result) => {
      if(result.data.statusCode === 400 && result.data.message == 'Application phone number is required') {
        localStorage.setItem('mobileRequired',true);
        this.props.history.push('/mobile-verification')
        return false
      }
      else if(result.data.statusCode == 403){
        let errorMessage = result.data.message;
        if (extractEmail(errorMessage) != null) {
            let extractErrorMessage = extractEmail(errorMessage);
            extractErrorMessage.map((src) => {
            let anchorTag = "<a target='_blank' style='color: #ff6200' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
            errorMessage = errorMessage.replace(src, anchorTag);
            })
        }
        this.setState({showErrorMessage: errorMessage})
        window.$('#socialMediaErrorModal').modal('show');
        return false;
      }
    }



    //checkstate 

    async checkLoginState(response) {
        if (response.authResponse) {
          // User is signed-in Facebook.
          let unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
            unsubscribe();
            // Check if we are already signed-in Firebase with the correct user.
            this.isUserEqual(response.authResponse, firebaseUser)
            .then((isUserEqual) => {
              if (!isUserEqual) {
                // Build Firebase credential with the Facebook auth token.
                let credential = firebase.auth.FacebookAuthProvider.credential(
                    response.authResponse.accessToken);
                
                // Sign in with the credential from the Facebook user.
                firebase.auth().signInWithCredential(credential)
                  .catch((error) => {
                    // Handle Errors here.
                
                    // ...
                  });
              } else {
                // User is already signed-in Firebase with the correct user.
              }
            })
          });
        } else {
          // User is signed-out of Facebook.
          firebase.auth().signOut();
        }
      }





      async isUserEqual(facebookAuthResponse, firebaseUser) {
        if (firebaseUser) {
          let providerData = firebaseUser.providerData;
          for (let i of providerData) {
            if (providerData[i].providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID &&
                providerData[i].uid === facebookAuthResponse.userID) {
              // We don't need to re-auth the Firebase connection.
              return true;
            }
          }
        }
        return false;
      }

    async handleFbLogin() {
        let provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope('user_birthday');
        provider.addScope('email');
        provider.setCustomParameters({
            'display': 'popup'
          });
        
        return firebase.auth().signInWithPopup(provider).then(function (result) {
           
                let token = result.credential.accessToken;
                console.log(result);
                let user = result.user;
                console.log(user);
                let userEmail = result.user.email;
                if(!userEmail){
                    userEmail=result.additionalUserInfo.profile.email;
                }
                return   {
                    firstname: user.displayName,
                    lastname: result.additionalUserInfo.profile.last_name,
                    email:userEmail,
                    user_id: result.additionalUserInfo.profile.id,
                    access_token: token,
                    refresh_token: user.refreshToken,
                    errorEmail: ''
                }

              

            }).catch((error) => {
              console.log(error);
                let errorMessage = error.message;
                if (error.email) {
                    return {email: error.email}
                   
                } else {
                    return {errorEmail: errorMessage}
                }

            });
    }



    redirectCodeScreen=()=>{
        window.$('#exampleModal').modal('hide');
      }

      appleResponseRecivedLoader = (responseStat)=>{
        this.props.appleResponseRecived(responseStat);
      }
    
    render() {
      const cookies = new Cookies();
        if (this.state.redirect) {
          if(this.state.userExists && cookies.get('retailerId') == undefined){
            return <Redirect push to='/welcome' />;
          }
          if(this.state.userExists && cookies.get('retailerId') != undefined){
            return <Redirect push to='/application' />;
          }
          if(!this.state.userExists){
            return <Redirect push to={'/application'} />;
          }
        }
        return (
          <div>
            <div className="social-links">
              {/* Twitter */}
              <span className="col-md-4 col-sm-3 p-0">
                <Link
                  to={this.props.fromWhere}
                  className="soQr--link" id="facebook"
                  onClick={this.handleFbUserLogin}
                >
                  <img src="images/facebook.svg" alt="Facebook" />
                </Link>
              </span>
              <span className="col-md-4 col-sm-3 p-0">
                <Twitter fromWherePath={'/register'} history={this.props.history} setUserId={this.props.setUserId} />
              </span>
              <span className="col-md-4 col-sm-3 p-0">
                <Link
                  to={this.props.fromWhere}
                  className="soQr--link" id="google"
                  onClick={this.handleGoogleUserLogin}
                >
                  <img src="images/google.svg" alt="Google" />
                </Link>
              </span>
              <span className="col-md-4 col-sm-3 p-0">
              <AppleUserLogin appleResponseRecived = {this.appleResponseRecivedLoader}></AppleUserLogin>
              </span>
            </div>
            {this.state.loginMessage &&
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" >
                        <div className="modal-content">
                       
                        <div className="modal-body">
                        <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid"/>
                                </div>
                                <h5 className="modal-title" id="exampleModalLabel">Social Login</h5>
                        {this.state.loginMessage &&
                         <p className="text-center mt-4">{this.state.loginMessage}</p>
                        }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={()=>this.redirectCodeScreen()} >Ok</button>
                        </div>
                        </div>
                    </div>
                </div>
          } 

            {/* server error popup modal */}
            <div className="modal fade model-error" id="socialMediaErrorModal" tabIndex="-1" data-keyboard="false" data-backdrop="static" aria-labelledby="serverErrorShowModellLabel" aria-hidden="true">
              <div className="modal-dialog" >
              <div className="modal-content">
                  
                  <div className="modal-body">
                      <div className="logo">
                          <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                      </div>
                      {/* Added the margin left and right spacing to message displayed in popup */}
                      <p className="text-center mt-4 mx-3">{parse(this.state.showErrorMessage)}</p>
                  </div>

                  <div className="modal-footer mb-4">
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => analyticsClickEvent('ok','')}>Ok</button>
                  </div>


              </div>
              </div>
            </div>
          </div>
                      
        );
    }

}

const mapStateToProps = (state) => {
    return {
        FireBaseStore: state.dataStore.FireBaseStore,
        userId: state.dataStore.userId,
    };
    
};


const mapDispatchToProps = (dispatch) => {
    return {
        socialLogin: (formData) => {
            return dispatch(socialLogin(formData));
        },
        setUserId: (userID) => {
          return dispatch({
            type: 'USER_ID',
            value: userID
        });
        }
    }
}

SocialMedia.propTypes = {
  fromWhere: PropTypes.string,
  setUserId: PropTypes.func,
  socialLogin: PropTypes.func,
  history: PropTypes.object,  
  appleResponseRecived:PropTypes.bool
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SocialMedia)