import axios from 'axios';
import config from '../config';
import {handlingSession} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const personalQuestions = () => {
  
    let url = config.hummBackendAppURL+'user/personalDetails'

    return async (dispatch) => {
        let resultResponse = [];
        await axios.get(url)
            .then(response => {
                dispatch({
                    type: 'PERSONAL_QUESTIONS',
                    value: response.data
                });
                resultResponse = response;
                return resultResponse;
            })
            .catch(async error => {
                await userLogs(url,'',error);
                if(error.response){
                    handlingSession(error.response.status);
                }
                resultResponse = error.response;
                return resultResponse;
            })

            return resultResponse;
    }
};


