import React from 'react';
import { Link } from 'react-router-dom';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import {addFavoritePartner} from '../../action/partner/addFavoritePartner';
import { addViewPartner } from '../../action/partner/addViewPartners';
import {removeFavoritePartner} from '../../action/partner/removeFavoritePartner';
import { thirdPartyWebsiteAlertPopupText } from '../../utils/const';
import { PropTypes } from 'prop-types';


class ViewedPartnerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            value:'',
            isFavAdd:'',
            isFavRemove:'',
            itemList:[],
            partnerID: ''
        }
        
        this.handleFavoriteAdd_viewedPartner = this.handleFavoriteAdd_viewedPartner.bind(this)
        this.handleFavoriteRemove_viewedPartner = this.handleFavoriteRemove_viewedPartner.bind(this)
        this.getupdater = this.getupdater.bind(this);
        
    }


     getupdater = async(partnerID,partnerName,partnerWebsitLink)=>{
        if(partnerWebsitLink == '' || partnerWebsitLink == null || partnerWebsitLink == undefined){
            return false;
          }
        let splitPartnerName = partnerName.split(' ').join('_');
        analyticsClickEvent('viewed_partner',splitPartnerName)

        const resultPartnerURL = this.checkURL(partnerWebsitLink);
        this.setState({partnerID: partnerID}, () => {
          localStorage.setItem("partnerWebsiteURL", resultPartnerURL);
          setTimeout(() => {
            window.$('#thirdPartyWebsiteModel').modal('show');
          }, 200);
        });
       
    }

    viewedPartnerChange=(viewList)=>{
        this.setState({itemList:viewList});
    }

    redirectionThirdPartnerSite = async () => {
        const obj={
            email:localStorage.getItem('email'),
            account: this.state.partnerID
        }
        await addViewPartner(obj);
        this.props.updaterecentviewpartner();
        
        if(localStorage.getItem("partnerWebsiteURL")) {
          window.open(localStorage.getItem("partnerWebsiteURL"), '_blank', 'noopener noreferrer');
          window.$('#thirdPartyWebsiteModel').modal('hide');
        }
    }
    
    componentDidMount(){
        this.setState({itemList:this.props.itemList});
    }
    
    
    async handleFavoriteAdd_viewedPartner(value,partnerName){
        let splitPartnerName = partnerName;
        if(partnerName != undefined){
            splitPartnerName = partnerName.split(' ').join('_');
        }
        analyticsClickEvent('viewed_heart_checked',splitPartnerName);
        const addDataParameter={email:localStorage.getItem('email'),account:value};
        const addFavouriteResponse = await addFavoritePartner(addDataParameter);
        const temporaryData = addFavouriteResponse.data;
        let currentState = this.state.itemList;
        let addFavouriteUpdated = currentState.map((item)=>{
            if(item.partnerId === temporaryData.account){
                item.partnerFavId = temporaryData.id
            
            }
            return item;
        })

         this.setState({itemList:addFavouriteUpdated});
         this.props.handleViewChange();
       
    }

    async handleFavoriteRemove_viewedPartner(value,partnerName){
        let splitPartnerName = partnerName;
        if(partnerName != undefined){
            splitPartnerName = partnerName.split(' ').join('_');
        }
        analyticsClickEvent('viewed_heart_unchecked',splitPartnerName);
        const removeDataParameters={email:localStorage.getItem('email'),account:value};

        removeFavoritePartner(removeDataParameters)
        .catch(error => console.error(error));
        let currentState = this.state.itemList;
        let removeFavouriteUpdated = currentState.map((item)=>{
            if(item.partnerId === value){
                item.partnerFavId = null
            
            }
            return item;
        })

         this.setState({itemList:removeFavouriteUpdated});
         this.props.handleViewChange();
       
     }

     checkURL=(url)=>{
        if(url!=null && url!=undefined && url!=''){
           let pattern = /^((http|https|ftp):\/\/)/;

           if(!pattern.test(url)) {
            return "http://" + url;
           }else{
            return url;
           }
        }else{
           return url 
        }
       
    }



    
    render() { 
        return (  
            <>
            <div className="gallery-partner">
            { this.state.itemList?.map((response) =>  <div className="featured-box partner-item" key={response.partnerId}>
            <div id={response.partnerId} className="featured-itemQr">
            <div className="recent-view-section">
                <a target rel="noreferrer" href="javascript:void(0)" onClick={_e => { this.getupdater(response.partnerId,response.partnerName,response.partnerWebSite).catch(error => console.error(error)) }}>
                    <div className="partner-img">
                        {response.partnerCoverImage==undefined &&
                      <img src="images/compu.png" alt={response.partnerName} />
                }
                {response.partnerCoverImage!=undefined &&
                      <img src={response.partnerCoverImage} alt={response.partnerName} 
                      onError={(evt) => evt.target.src = "images/partner-placeholder.png"} />
                }
                        <div className="overlay">
                        </div>
                    </div>
                </a>
                <div className="fav-icon-section">
                {response.partnerFavId===undefined &&(<a value={response.partnerId} onKeyDown={(e) => { e.preventDefault(); }}  onClick={e => { this.handleFavoriteAdd_viewedPartner(response.partnerId,response.partnerName).catch(error => console.error(error)) }} className='fav-icon-btn'><img src="images/heart-white.svg" alt="Fav" /></a>) }
                    {response.partnerFavId!==undefined &&(<a value={response.partnerId} onKeyDown={(e) => { e.preventDefault(); }} onClick={e => { this.handleFavoriteRemove_viewedPartner(response.partnerId,response.partnerName).catch(error => console.error(error)) }} className='fav-icon-btn active'><img src="images/heart.svg" alt="Fav-active" /></a>) }
                </div>   
                <a target rel="noreferrer" href="javascript:void(0)" onClick={e => { this.getupdater(response.partnerId,response.partnerName,response.partnerWebSite).catch(error => console.error(error)) }}>                        
                    <div className="overlay-logo-item">
                        <span className="featured-caption">
                            <div className="slider-logo-image">
                                {response.partnerLogo != null &&
                                   <img src={response.partnerLogo} alt={response.partnerName}/>
                                }
                                {response.partnerLogo == null &&
                                    <h6 >{response.partnerName}</h6>
                                }
                            </div>
                        </span>
                    </div>
                </a>
                <div className="qtwo-calculator">
                    <Link
                        to={{
                            pathname: "/quote-calculator",
                            state: { id: response.partnerId, store_Name: response.partnerName }
                        }} onClick={localStorage.setItem('prevRoute',window.location.pathname)}
                    >
                        <button className="qc-click-btn"
                        onClick={function(_e){ analyticsClickEvent('partner_quote_calculator', '') }}
                        >
                             <span className=''>
                                Get A Quote                                
                            </span>
                            <img src="images/right-arrow-shop.svg" width={25} alt="icon" />
                        </button>
                    </Link>
                </div>
            </div>
            </div>
        </div>) 
        } 
        </div>
        <div className="modal model-error fade" id="thirdPartyWebsiteModel" data-keyboard="false" tabIndex="-1" aria-labelledby="thirdPartyWebsitePopupLabel">
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-header">
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img src="images/modal-logo.svg" className="img-fluid" alt="logo" />
                </div>

                <p className=" mb-0 text-center mt-4">{thirdPartyWebsiteAlertPopupText}</p>
              </div>

              <div className="modal-footer mb-4 ">
                <button type="button" data-dismiss="modal" className="btn btn-outline btn-warning" >Cancel</button>
                <button type="button" className="btn btn-primary" onClick={() => {
                    this.redirectionThirdPartnerSite().catch(error => console.error(error))}}>Ok</button>
              </div>


            </div>
          </div>
        </div>
        </>
        
        );
    }
}

ViewedPartnerList.propTypes = {
    updaterecentviewpartner: PropTypes.func,
    itemList: PropTypes.array,
    handleViewChange: PropTypes.func
}
 
export default ViewedPartnerList;