import React from "react";
import 'firebase/compat/auth';
import {twitterLogin, twitterRequestToken } from "../../../action/auth/login";
import { Link,Redirect } from 'react-router-dom';
import {twittersocialLogin } from "../../../action/auth/socialLogin";
import { analyticsClickEvent, analyticsLoginSuccessEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { getApplicationImage } from "../../../action/avatar/getAvatar";
import Cookies from 'universal-cookie';
import parse from 'html-react-parser';
import { extractEmail } from "../../../utils/extractEmail";
import { PropTypes } from 'prop-types';

export class Twitter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loginMessage :'',
          redirect:false,
          customPath:'/welcome',
          userExists: false,
          showErrorMessage: ''
        };
        localStorage.setItem('contentFrom',this.props.fromWhere);
      }
     
  componentDidMount(){
        localStorage.clear();
        this.initialLoad()
  }

  getApplicationBannerImage_forTwitter = async() => {
    if(localStorage.getItem('fromWhere') !='skip'){
        const appImage = await getApplicationImage();
        if (appImage && appImage.status === 201 && Array.isArray(appImage.data.image) && appImage.data.image.length !== 0 && appImage.data.image[1] != undefined) {
                let appImg = appImage.data.image[1];
                localStorage.setItem('applicationImage', appImg);                                   
        }
        else {
          localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
        }
     }
}

  initialLoad = async () => {
    
  if(window.location.href.includes("oauth_verifier") ) {
      let objects = window.location.href.split("?")[1].split("&")

    if(objects.length == 2){
      let oauth_tokens = objects[0]
      let oauth_verifiers = objects[1]
      let oauth_token = oauth_tokens.split("=")[1]
      let oauth_verifier = oauth_verifiers.split("=")[1]
      let objs = {
        "oauth_token": oauth_token,
        "oauth_verifier": oauth_verifier
      }
      let results = await twitterLogin(objs)
      localStorage.setItem('UserName',results.data.name);
      localStorage.setItem('email',results.data.email);
      localStorage.setItem('uuid',results.data.id);
      localStorage.setItem('SocialType', 4);
      let checkTwitterLoginResult = await this.checkTwitterLoginResponse(results);
      if(checkTwitterLoginResult === false) {
        return false;
      }
      if(results.data.email === '' || results.data.email === undefined ){
              this.setState({loginMessage:'Please try again with an account which has email id registerd with it'});
              window.$('#exampleModal').modal('show');
              return false;
            }

        }
      }
  }

  checkTwitterLoginResponse = async (results) => {
    if (results.status ===200 && results.data.email.length >1) {
      let obj = {
        "username":localStorage.getItem('UserName'),
        "email": localStorage.getItem('email'),
        "uu_id": localStorage.getItem('uuid'),
        "provider": localStorage.getItem('SocialType'),
        'Application_Channel': 'Web',
        "Build_Version": "Web App"
      }
      const twitterResult = await twittersocialLogin(obj) 
      let responseMessage = twitterResult.data.message;
      switch(responseMessage){
        case 'This email already using with Gmail account so must login with Gmail':
        case 'This email already using with Facebook account so must login with Facebook':
        case 'This email already using with Twitter account so must login with Twitter':
          localStorage.clear();
          this.setState({loginMessage:twitterResult.data.message})
          window.$('#exampleModal').modal('show');
          return false;
        case 'This email already using with Humm account so must login with Humm':
          this.setState({loginMessage: twitterResult.data.message});
          window.$('#exampleModal').modal('show');
          return false;
      }
      localStorage.setItem('token', twitterResult.data.data.access_token);
      localStorage.setItem('sessionId',twitterResult.data.data.session_id);
      localStorage.setItem('csrfToken',twitterResult.data.data.csrf_token);

      localStorage.setItem('emailConsent', twitterResult?.data?.data?.user_details?.marketing_consent?.email_marketing_consent);
      localStorage.setItem('smsConsent', twitterResult?.data?.data?.user_details?.marketing_consent?.sms_marketing_consent);
      localStorage.setItem('mobileConsent', twitterResult?.data?.data?.user_details?.marketing_consent?.mobile_app_marketing_consent);

      // Set the state whether the user already exists or not
      this.setState({userExists: twitterResult.data.data.user_exists});
      localStorage.setItem('userExists',twitterResult.data.data.user_exists);
      let checkValidateTwitterResult = this.checkTwitterSocialLoginResponse(twitterResult,responseMessage);
      if(checkValidateTwitterResult === false) {
        return false;
      }      
    }else if(results.status){
        if(results.status !==200){
          return false;
        }
      }
  }

  checkTwitterSocialLoginResponse = (twitterResult,responseMessage) => {
    const statusCode=[200];
      if(twitterResult.data.statusCode === 400 && responseMessage == 'Application phone number is required'){
        this.props.setUserId(localStorage.getItem('uuid'))
        localStorage.setItem('mobileRequired',true);
        this.props.history.push('/mobile-verification')
        return false
      }
      else if(twitterResult.data.statusCode == 403){
        let errorMessage = twitterResult.data.message;
        if (extractEmail(errorMessage) != null) {
            let extractErrorMessage = extractEmail(errorMessage);
            extractErrorMessage.map((src) => {
            let anchorTag = "<a target='_blank' style='color: #ff6200' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
            errorMessage = errorMessage.replace(src, anchorTag);
            })
        }
        this.setState({showErrorMessage: errorMessage})
        window.$('#twitterErrorModel').modal('show');
        return false;
      } else if (statusCode.includes(twitterResult.data.statusCode)) {
        analyticsLoginSuccessEvent('login','social_media_login')
        if(!this.state.userExists){
          this.getApplicationBannerImage_forTwitter();
        }
        if(twitterResult.data.data.user_details.userid){
          localStorage.setItem('userID', twitterResult.data.data.user_details.userid);
        }
          this.setState({redirect: true});
      } else if(twitterResult.data.message == "Please enter the verification code we've sent by SMS" && twitterResult.data.statusCode === 200){
        localStorage.setItem('mobileRequired',true);
        this.props.setUserId(localStorage.getItem('uuid'))              
        this.props.history.push('/mobile-verification')

        return false
      }
  }


  redirectCodeScreen=()=>{
    window.$('#exampleModal').modal('hide');
  }
    
  async handleTwitterUserLogin() {
    analyticsClickEvent('twitter_login','')
    let obj = {
            "callback":window.location.href.split('?')[0]
      }
    const userRequestToken = await twitterRequestToken(obj);
    window.location.replace(userRequestToken.data.redirect_url)
    }

    
  render() { 
    const cookies = new Cookies();
      if (this.state.redirect) {
        if(this.state.userExists && cookies.get('retailerId') == undefined){
          return <Redirect push to={this.state.customPath} />;
        }
        if(this.state.userExists && cookies.get('retailerId') != undefined){
          return <Redirect push to={'/application'} />;
        }
        if(!this.state.userExists){
          return <Redirect push to={'/application'} />;
        }
      }
    return ( 
      <div>
          <span className="col-md-4 col-sm-3 p-0">
          <Link to={this.props.fromWhere} 
            className="soQr--link" id="twitter"
            onClick={this.handleTwitterUserLogin} >
            <img src="images/twitter.png" alt="Twitter" />
            </Link>
        </span>

        {this.state.loginMessage &&
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" >
                    <div className="modal-content">
                  
                    <div className="modal-body">
                    <div className="logo">
                                <img src="images/modal-logo.svg" className="img-fluid" alt="logo"/>
                            </div>
                            <h5 className="modal-title" id="exampleModalLabel">Social Login</h5>
                    {this.state.loginMessage &&
                    <p className="text-center mt-4">{this.state.loginMessage}</p>
                    }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={()=>this.redirectCodeScreen()} >Ok</button>
                    </div>
                    </div>
                </div>
              </div> }

          {/* Server error popup modal */}
            <div className="modal model-error fade" id="twitterErrorModel" tabIndex="-1" data-keyboard="false" data-backdrop="static" aria-labelledby="serverErrorShowModellLabel" aria-hidden="true">
              <div className="modal-dialog" >
                <div className="modal-content">
                    
                    <div className="modal-body">
                        <div className="logo" id='twitterError-hummLogo'>
                            <img src="images/modal-logo.svg" className="img-fluid" alt="logo" />
                        </div>
                        {/* Added the margin left and right spacing to message displayed in popup */}
                        <p className="text-center mx-3 mt-4">{parse(this.state.showErrorMessage)}</p>
                    </div>

                    <div className="modal-footer mb-4">
                        <button type="button" data-dismiss="modal" className="btn btn-primary"  onClick={() => analyticsClickEvent('ok','')}>Ok</button>
                    </div>


                </div>
              </div>
            </div>
        </div>

      );
  }
  }

Twitter.propTypes = {
  fromWhere: PropTypes.string,
  setUserId: PropTypes.func,
  history: PropTypes.object,
}

export default Twitter;