import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const getRetailer = (formData) => {

    let url = config.hummBackendAppURL + 'application/retailer-id-from-purchase-link'
    return axios.post(url, formData, { headers: sessionID() }).then(res => {
        return res;
    }).catch(async error => {
        await userLogs(url, formData, error);
        if (error.response) {
            handlingSession(error.response.status);
        }
        return error.response
    })
};