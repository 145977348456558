import React from 'react';
import { PropTypes } from 'prop-types';

export class SessionExpiered extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

          }
    }

    componentDidMount() {
       setTimeout(() => {
         localStorage.clear();
         this.props.history.push('/login')
      }, 5000);
      
    }

   
    render() { 
        return ( 
            <div className="layout-fixed">
            <div className="wrapper welcome-page">
                <div className="logo">
                    <img src="images/logo.svg" className="img-fluid" alt="welcome logo"/>
                </div>
                <div className="welcome-content">
                <p>Your session has expired.<br></br> Please login to continue.</p>
                </div>
            </div>
          </div> 
         );
    }
}

SessionExpiered.propTypes = {
    history: PropTypes.object
}

export default SessionExpiered;