import React from 'react';
import Element from '../element';
import parse from 'html-react-parser';
import { PropTypes } from 'prop-types';

export class FormType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    displayApplicationSubTitle = () => {
        return localStorage.getItem('fromWhere') === 'apply_now' ? "dynamic-input d-none" : "dynamic-input"
    }
    render() {
        
        return (
            <React.Fragment>
                {this.props.field_questions.length===0 &&
                    <div className={this.displayApplicationSubTitle()}>
                    <div className="form-group"> 
                    {this.props.field_value!=='' &&
                    <h4 className="application-sub-title">{parse(this.props.field_value)}</h4>
                    }
                    <Element fieldItem={this.props.field_questions} stage={this.props.stage}  showValidation={this.props.showValidation}/>
                    </div>
                    </div>
                }
                {this.props.field_questions.length>1 &&
                <React.Fragment>
                    {this.props.field_value!=='' &&
                        <h4 className="application-sub-title mb-3 d-none">{parse(this.props.field_value)}</h4>
                    }
                    {/* <div className="col-md-6 col-12"></div>  */}
                    <Element fieldItem={this.props.field_questions} stage={this.props.stage}  showValidation={this.props.showValidation}/>
                    {/* <div className="col-md-6 col-12"></div>
                     */}
                </React.Fragment>
                }
                 </React.Fragment>
                
           
          );
    }
}

FormType.propTypes = {
    field_questions: PropTypes.array,
    field_value: PropTypes.string,
    stage: PropTypes.string,
    showValidation: PropTypes.string,
}
 
export default FormType;