import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


 export const addFavoritePartner = async (formData) => {
    let url = config.hummBackendAppURL+'favorite-partner/add'

   return  axios.post(url,formData,{headers:sessionID()}).then(res => {
        return res.data;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })
};