
import axios from 'axios';
import {handlingSession,sessionID} from '../../utils/handlingSession'
import { userLogs } from '../logs/userLogs';

export const QuoteTool = async (formData) => {
    let url = process.env.REACT_APP_BACKEND+'pca/quote-tool'

   return axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async error => {
        await userLogs(url,formData,error);
        if(error.response){
            handlingSession(error.response.status);
        }
        return error.response
    })


};


