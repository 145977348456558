import React from "react";
import { connect } from "react-redux";
import { resetPasswords } from "../../action/auth/resetPassword";
import { avatar } from "../../action/avatar/avatar";
import { emailCheck } from "../../action/auth/login";
import { sendMarketingConstent } from "../../action/marketingConstent/marketingConstent";
import Loader from "../loader/loader";
import {
  validatePasswordField,
  validateConfirmPasswordField,
} from "../validation/passwordValidation";
import { Link } from "react-router-dom";
import { getAvatar } from "../../action/avatar/getAvatar";
import { analyticsClickEvent, analyticsTypeEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";
import { GTMUserIdAndLoginState } from "../../utils/GtmDatalayer";
import { PropTypes } from 'prop-types';

export class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isConfirm: false,
      errors: {
        newPasswordError: null,
        confirmPasswordError: null,
        oldPasswordError: null,
      },
      isEnabled: true,
      serverError: null,
      newConfirmPassError:'',
      userName: localStorage.getItem("UserName"),
      email:'',
      passNew: "********",
      selectedFile: null,
      isDisabled: false,
      profilePicture: props.profilePicture,
      unSupportImageType: null,
      mobileNumber: "",
      loading: false,
      address:'',
      fileName: "",
      isSMSConsentChecked: false,
      isEmailConsentChecked: false,
      isMobileAppConsentChecked: false,
      successMessage: '',
      accountDeletionUrl: 'https://www.shophumm.com/uk/account-deletion/'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleApi = this.handleApi.bind(this);
    this.handleUnsetStateImage = this.handleUnsetStateImage.bind(this);
  }

  async componentDidMount() {
    // Push the customer id and loginState if available on this page to the dataLayer
    window.dataLayer = window.dataLayer || {};
    GTMUserIdAndLoginState();

    await this.initialLoad();
    await this.getUserDetails();
    if(localStorage.getItem("email")!=undefined && localStorage.getItem("email")!=null){
      this.setState({email:localStorage.getItem("email").toLocaleLowerCase()});
    }
    
  }

  initialLoad = async () => {
    await this.setState({ loading: true });
    const obj = {
      email: localStorage.getItem("email"),
    };

    const result = await emailCheck(obj);
    this.setState({ loading: false });

    if (result && result.data.statusCode === 400) {
      if (
        result.data.message ===
        "This is social media login. Click ok to reset the password"
      ) {
        this.setState({ isDisabled: true });
      }
    }
  };

  getUserDetails = async () => {
    const obj={
      email:localStorage.getItem('email')
  }
    const imageResult = await getAvatar(obj);
    console.log(imageResult);
    if(imageResult !== null && imageResult !== undefined && imageResult !== ''){
      if(imageResult && imageResult.data.statusCode===200){
        let mob = imageResult.data.data.mobile_number;
        let a1 = imageResult.data.data.address_one;

        let address = ''
            if(mob !== '' && mob !== null && mob !== undefined){
                this.setState({mobileNumber:mob});
            }
        if(a1 !== null && a1 !== undefined && a1 !== ''){
          address += a1
        }

        this.setState({address:address})

          this.setState({
            isEmailConsentChecked: imageResult.data.data.email_marketing_consent, 
            isSMSConsentChecked: imageResult.data.data.sms_marketing_consent,
            isMobileAppConsentChecked: imageResult.data.data.mobile_app_marketing_consent
          }, () => {
            this.updateLocalMarketingConsent();
          });      

          this.assignValueForAccountDeletionUrl(imageResult.data.data);

        console.log(address)
      }
    }
  }

  oldPasswordValidation = (value, errors) => {
    if (value.trim() === "") {
      errors.oldPasswordError = "Password is required";
    } else if (value.trim().length < 8) {
      errors.oldPasswordError = `Password needs to be at least eight characters`;
    } else {
      errors.oldPasswordError = "";
    }

    return errors;
  }

  assignValueForAccountDeletionUrl = (dataResponse) => {
    if(dataResponse.accountDeletionURL !== undefined && dataResponse.accountDeletionURL !== '') {
      this.setState({accountDeletionUrl: dataResponse.accountDeletionURL})     
    }
  }

  updateLocalMarketingConsent = () => {
    localStorage.setItem('smsConsent', this.state.isSMSConsentChecked ?? false);
    localStorage.setItem('mobileConsent', this.state.isMobileAppConsentChecked ?? false);
    localStorage.setItem('emailConsent', this.state.isEmailConsentChecked ?? false)
  }

  updateMarketingConstent = async() =>{
    const obj = {
      email: localStorage.getItem('email'),
      email_marketing_consent: this.state.isEmailConsentChecked,
      sms_marketing_consent: this.state.isSMSConsentChecked,
      mobile_app_marketing_consent: this.state.isMobileAppConsentChecked,
    }
    let result = await sendMarketingConstent(obj);
    this.setState({
      isEmailConsentChecked: result.data.data.email_marketing_consent, 
      isSMSConsentChecked: result.data.data.sms_marketing_consent,
      isMobileAppConsentChecked: result.data.data.mobile_app_marketing_consent
    }, () => { 
      localStorage.setItem('emailConsent', this.state.isEmailConsentChecked ?? false);
      localStorage.setItem('smsConsent', this.state.isSMSConsentChecked ?? false);
      localStorage.setItem('mobileConsent', this.state.isMobileAppConsentChecked ?? false);
    })
  }

  async handleChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;
 analyticsTypeEvent(name);


     this.setState({ [name]: value });
     this.setState({ serverError: null });

    let errors = this.state.errors;

    switch (name) {
      case "oldPassword":
        errors = this.oldPasswordValidation(value, errors);
        break;
      case "newPassword":
        errors.newPasswordError = validatePasswordField(value,this.state.confirmPassword);
 if(errors.newPasswordError==="Please Enter the New password" || errors.newPasswordError === ''){
                    errors.newPasswordError = '';
                }
                if( errors.newPasswordError === "Passwords do not match" ||  errors.newPasswordError === ""){
                  errors.confirmPasswordError = '';
                } 
               
        break;
      case "confirmPassword":
        errors.confirmPasswordError = validateConfirmPasswordField(
          value,
          this.state.newPassword
        );
        if( errors.confirmPasswordError === "Passwords do not match" ||  errors.confirmPasswordError === ""){
          errors.newPasswordError = '';
        }
        break;
      case "mobile-app-radio":
        this.setState({isMobileAppConsentChecked: event.target.checked }, this.updateMarketingConstent);
        break;
      case "smsRadio":
        this.setState({isSMSConsentChecked: event.target.checked }, this.updateMarketingConstent);     
        break;
      case "emailRadio":
        this.setState({isEmailConsentChecked: event.target.checked }, this.updateMarketingConstent);    
        break;
      default:
        errors.confirmPasswordError = "";
        errors.oldPasswordError = "";
        errors.newPasswordError = "";
    }

    this.setState({ errors, [name]: value });

    let errorsData = this.state.errors;


    if (
      errorsData.oldPasswordError === "" &&
      errorsData.newPasswordError === "" &&
      errorsData.confirmPasswordError === ""
    ) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  }

  async handleSubmit(event) {
   analyticsClickEvent('change_password_popup_confirm','')
    event.preventDefault();
if(this.state.newPassword !== this.state.confirmPassword){
         this.setState({newConfirmPassError: 'New Password and Confirm Password do not match'})
         return false;
       }else{
        this.setState({newConfirmPassError: ''})
       }

    const resetPassword = {
      email: localStorage.getItem("email"),
      currentPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
    };

    const results = await resetPasswords(resetPassword);

    if (results && results.data.statusCode === 200) {
      await this.setState({ serverError: "" });
      window.$("#changePsw").modal("hide");
      window.$("#changeconfirmPsw").modal("show");
      await this.setState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } else {
      await this.setState({ serverError: results.data.message });
    }
  }
  handleApi(_e) {
   analyticsClickEvent('changed_password_popup_confirm','')
    window.$("#changeconfirmPsw").modal("hide");
  }

  onFileUpload = async() => {
    this.analyticsclickevent('upload_profile_picture_submit','')
    if (this.state.selectedFile === null) {
      return false;
    }
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    formData.append("email", localStorage.getItem("email"));

    await this.handleUploadApi(formData);
    return true;
  };

  async handleUploadApi(formData) {
    this.setState({ loading: true });
    const imageUpload = await avatar(formData);
    this.timer = setTimeout(() => {
      this.setState({ loading: false });
    }, 0);
    console.log(this.timer);

    if (
      imageUpload &&
      imageUpload.status === 201 &&
      imageUpload.data.image_path
    ) {
      this.fileupref.value = null;

      this.setState({ selectedFile: null });

      let userPic = "data:image/jpeg;base64," + imageUpload.data.image_path;
      this.props.setProfilePic(userPic);

      this.setState({unSupportImageType: '', successMessage: imageUpload.data.message})
      setTimeout(() => {
        window.$("#imageUpload").modal("hide");
        this.setState({successMessage: ''})
      }, 1800);
    }
    else {
      this.setState({ unSupportImageType: imageUpload.data.message})
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.profilePicture !== nextProps.profilePicture) {
      return {
        profilePicture: nextProps.profilePicture,
      };
    }
    return null;
  }

  handleUnsetState = (e) => {
    console.log(e);
    analyticsClickEvent('edit_password','')
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      errors: {
        newPasswordError: null,
        confirmPasswordError: null,
        oldPasswordError: null,
      },
      serverError: null,
      isEnabled: true,
      newConfirmPassError:''
    });
  };

  myAccountBackButtonClickEvent = () => {
    this.analyticsclickevent('myAccountBackBtn')
  }

  analyticsclickevent = (data) => {    
    if(data === 'myAccountBackBtn'){
       analyticsClickEvent('my_account_back','')
    }else if(data === 'passwordChangeOTPModel'){
       analyticsClickEvent('password_change_OTP_popup_close','')
    }

  }

  handleUnsetStateImage(e) {
   analyticsClickEvent('change_profile_image','')
    this.fileupref.value = null;

    this.setState({
      unSupportImageType: null,
      selectedFile: null,
      fileName: null,
    });
  }

  imageUploadModal = () => {
    return (
      <div
        className="modal fade "
        id="imageUpload"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" >
          <div className="modal-content">
            <div className="modal-header">
              <a
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick = {(e) => {
                  analyticsClickEvent('upload_a_profile_pic_popup_close','')
                }}
                onKeyDown={(e) => { e.preventDefault(); }}
              >
                <span aria-hidden="true">&times;</span>
              </a>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="exampleModalLabel">
                Upload Image
              </h5>
              <div className="upload-btn-wrapper">
                <button className="btn profile-img-btn">
                  Upload a profile picture
                </button>
                <input
                  type="file"
                  required
                  accept="image/*"
                  ref={(comp) => (this.fileupref = comp)}
                  onChange={(e) => {
                    console.log(e);
                    analyticsClickEvent('upload_a_profile_picture','')
                    this.setState({ unSupportImageType: null, fileName: "" });
                    if (e.target.files.length === 0) {
                      return false;
                    }
                    this.setState({ fileName: e.target.files[0].name });

                    this.setState({ selectedFile: e.target.files[0] }, () => {

                      if (this.state.selectedFile !== null) {
                        let filename = this.state.selectedFile.name;
                        let fsize = this.state.selectedFile.size;
                        const file = Math.round(fsize / 1024);
                        if (!/\.(jpe?g|png|gif|bmp)$/i.test(filename)) {
                          this.setState({
                            selectedFile: null,
                            unSupportImageType:
                              "Only Support jpg,jpeg,png,gif,bmp file format",
                          });
                        }
                        if (file >= 2048) {
                          this.setState({
                            selectedFile: null,
                            unSupportImageType:
                              "User image size must lesser than 2 MB",
                          });
                        }
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-error-show">
              <p className="error-message code-error my-avatar-error">
                {this.state.unSupportImageType ? (
                  <span className="error-message">
                    {this.state.unSupportImageType}
                  </span>
                ) : (
                  <span>{this.state.fileName}</span>
                )}
              </p>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-primary ${
                  this.state.selectedFile === null ? "disabled" : ""
                }`}
                onClick={() => { this.onFileUpload().catch(error => console.error(error)); }}
              >
                Submit
              </button>
              <p className="mt-2 mb-0">
              {this.state.successMessage !== '' && (
                  <span className="succes-message">
                    {this.state.successMessage}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  marketingConstent(){
    return(
      <div className="comm-settings row mt-3">
        <div className="col-md-4 col-12">
            <h4>Marketing Preferences</h4>
        </div>

        <div className="col-md-10 col-12">
          <p>humm would love to send you <span className="font-weight-bold">exclusive offers</span> from 
                our partner retailers and keep you up to date with the latest news from us. Please use this section to update your consent.</p>
        </div>
        <div className="myaccount-marketingconsent--group col-md-12 col-12">
              
              <div className="form-check form-group mt-0 mb-1">
                  <input type="checkbox" name="emailRadio" id="emailRadio" className="form-check-input" 
                  checked={this.state.isEmailConsentChecked} onChange={ (e) => { this.handleChange(e).catch(error => console.error(error)) }} 
                   />
                  <label htmlFor="emailRadio" className="radio-label font-weight-bold">Email</label>
              </div>

              <div className="form-check form-group mt-0 mb-1">
                  <input type="checkbox" name="smsRadio" id="smsRadio" className="form-check-input" 
                  checked={this.state.isSMSConsentChecked} onChange={ (e) => { this.handleChange(e).catch(error => console.error(error)); }} />
                  <label htmlFor="smsRadio" className="radio-label font-weight-bold">SMS</label>
              </div>
        </div>
      </div>
    );
  }


  analyticsClickEventForAccountDeletion = () => {
    analyticsClickEvent('account_deletion_button', '');
  }

  hidePopup = () => {
    window.$('#accountDeletionRequest').modal('hide')
}

  render() {
    return (
      <div className="wrapper">
        <Loader loaderStatus={this.state.loading} />
        <section className="main">
          <div className="myaccount-section">
            <div className="container">
              <div className="my-account-item">
                <div className="back-btn-item mb-3">
                  <Link to="/home"
                    // onClick={() => {
                    //   this.props.history.push("/home");
                    // }}
                    className="back-btnQr" onClick={this.myAccountBackButtonClickEvent}
                  >
                    <img src="images/back.svg" alt="<-" /> Back
                  </Link>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="myaccount-header">
                      <h3>My Account</h3>
                    </div>
                  </div>
                </div>
                <div className="my-account-edit">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="profile-img-sec">
                        {this.state.profilePicture ? (
                          <img
                            src={this.state.profilePicture}
                            className="img-fluid"
                            alt="user "
                          />
                        ) : (
                          <div className="no-profile-image">
                          {localStorage.getItem('UserName')!=='' && localStorage.getItem('UserName')!==null &&
                            <span className="no-profile-image-letter-profile">{localStorage.getItem('UserName').substring(0, 1).toUpperCase()}</span>
                          }
                          </div>
                        )}

                        <div className="add-icon-user">
                          <a
                            className="add-btn"
                            data-toggle="modal"
                            id="image-upload-popup"
                            data-target="#imageUpload"
                            onClick={(e)=>this.handleUnsetStateImage(e)}
                            onKeyDown={(e) => { e.preventDefault(); }}
                          >
                            <img
                              src="images/add-icon.svg"
                              alt="+"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="myaccount-form">
                        <from>
                          <div className="form-group floating-label-group">
                            <input
                              type="text"
                              className="form-control"
                              aria-describedby="nameHelp"
                              placeholder=" "
                              name="userName"
                              value={this.state.userName}
                              readOnly
                              required
                            />
                            <label
                              className="floating-label"
                              htmlFor="exampleInputName"
                            >
                              Name
                            </label>
                          </div>
                          <div className="form-group floating-label-group">
                            <input
                              type="email"
                              className="form-control"
                              aria-describedby="emailHelp"
                              placeholder=" "
                              name="email"
                              value={this.state.email}
                              required
                              readOnly
                            />
                            <label
                              className="floating-label"
                              htmlFor="exampleInputEmail"
                            >
                              Email{" "}
                            </label>
                          </div>
                          {this.state.isDisabled !== true && (
                            <div className="form-group floating-label-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=" "
                                name="password"
                                value={this.state.passNew}
                                required
                              />
                              <label
                                className="floating-label"
                                htmlFor="exampleInputPassword"
                              >
                                Password
                              </label>
                              <span className="edit-item">
                                <p
                                
                                  className="edit-btn-form"
                                  data-toggle="modal"
                                  data-target="#changePsw"
                                  onClick={(e) => this.handleUnsetState(e)}
                                  onKeyDown={(e) => { e.preventDefault(); }}
                                >
                                  Edit
                                </p>
                              </span>
                            </div>
                          )}
                          <div className="form-group floating-label-group">
                            <input
                              type="number"
                              className="form-control"
                              aria-describedby="Phone"
                              placeholder=" "
                              name="mobileNumber"
                              value={this.state.mobileNumber}
                              disabled
                              required
                            />
                            <label
                              className="floating-label"
                              htmlFor="exampleInputPhone"
                            >
                              Mobile Number{" "}
                            </label>
                          </div>
                          <div className="form-group floating-label-group">
                            <input
                              type="text"
                              className="form-control"
                              aria-describedby="addresHelp"
                              placeholder=" "
                              name="address"
                              value={this.state.address}
                              disabled
                              required
                            />
                            <label
                              className="floating-label"
                              htmlFor="exampleInputAddress"
                            >
                              Address{" "}
                            </label>
                          </div>                          
                        </from>

                        <p className="account-deletion">To request deletion of your account, <a className="apply-important-info-link" 
                          href={this.state.accountDeletionUrl} target="_blank"
                          rel="noopener noreferrer" onClick={this.analyticsClickEventForAccountDeletion} >click here</a>.
                        </p>
                      </div>
                    </div>
                      {this.marketingConstent()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade"
          id="changePsw"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(_e) => analyticsClickEvent('change_password_popup_close','')}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h5 className="modal-title" id="exampleModalLabel">
                  Change Password
                </h5>
                <form>
                  <div className="form-group modal-error-message">
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      name="oldPassword"
                      value={this.state.oldPassword}
                      onChange={(e) => { this.handleChange(e).catch(error => console.error(error)) }}
                      placeholder="Old Password"
                    />

                    <p className="error-message code-error my-account-error ">
                      {this.state.errors.oldPasswordError ? (
                        <span style={{ color: "Red" }}>
                          {this.state.errors.oldPasswordError}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>

                  <div className="form-group modal-error-message psw-error-shown">
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword2"
                      name="newPassword"
                      value={this.state.newPassword}
                      onChange={(e) => { this.handleChange(e).catch(error => console.error(error)) }}
                      placeholder="New Password"
                    />
                    <p className="error-message code-error my-account-error">
                      {this.state.errors.newPasswordError ? (
                        <span style={{ color: "Red" }}>
                          {this.state.errors.newPasswordError}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>

                  <div className="form-group modal-error-message">
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword3"
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      onChange={(e) => { this.handleChange(e).catch(error => console.error(error)) }}
                      placeholder="Confirm Password"
                    />
                    <p className="error-message code-error my-account-error">
                      {this.state.errors.confirmPasswordError ? (
                        <span style={{ color: "Red" }}>
                          {this.state.errors.confirmPasswordError}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </form>

                <p className="error-message code-error my-account-error">
                  {this.state.serverError && this.state.serverError!='Session ID is expired'  ? (
                    <span style={{ color: "Red" }}>
                      {this.state.serverError}
                    </span>
                  ) : ("")}
                {this.state.newConfirmPassError ?(
                          <span style={{ color: "Red" }}>
                            {this.state.newConfirmPassError}
                          </span>
                        ):''}
                </p>
              </div>
              <div className="modal-footer changePassword-popup">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target={
                    this.state.serverError === "" ? "#changeconfirmPsw" : ""
                  }
                  disabled={this.state.isEnabled}
                  className="btn btn-primary"
                  onClick={(e) => { this.handleSubmit(e).catch(error => console.error(error)) }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div
          className="modal fade hide"
          id="changeconfirmPsw"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick = {(e) => {analyticsClickEvent('changed_password_popup_close','')}}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h5 className="modal-title" id="exampleModalLabel">
                  Change Password
                </h5>
                <p>Thank you. Your password has now been changed.</p>
              </div>
              <div className="modal-footer changePassword-popup">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.handleApi(e)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
        className="modal fade hide"
        id="accountDeletionRequest"
        tabIndex="-1"
        aria-labelledby="accountDeletionRequestModalLabel"
        aria-hidden="true"
        >
            <div className="modal-dialog" >
                <div className="modal-content">
                <div className="modal-header border-0"> 
                    <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(_e) => analyticsClickEvent('close','')}  
                    style={{opacity:0.5, position: "absolute", top: "20px", right: "20px", fontSize: "30px"}}                
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>               
                </div>
                <div className="modal-body">
                    <div className="logo">
                        <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                    </div>
                    <p className="mb-0">You can submit an account deletion request by clicking the Contact Us button below.</p>
                </div>
                <div className="modal-footer border-0 pb-4 d-flex justify-content-center">                
                    <a
                    href="https://www.shophumm.com/uk/contact-us/customer-contact/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-us-button"
                    onClick={() => this.hidePopup() }
                    >
                    Contact Us
                    </a>
                </div>
                </div>
            </div>
        </div>

        {this.imageUploadModal()}
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    profilePicture: state.dataStore.profilePicture,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setProfilePic: (userPic) => {
      return dispatch({
        type: "SET_PROFILE_PIC",
        value: userPic,
      });
    },
  };
};

MyAccount.propTypes = {
  profilePicture: PropTypes.string,
  setProfilePic: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
