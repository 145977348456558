import React, { Component } from "react";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import { Redirect } from 'react-router-dom';
import { firebaseLoadingEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";

export default class HummAnimation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect:false

          }
    }

    componentDidMount() {
        firebaseLoadingEvent('humm_animation_loading')
        this.timer = setTimeout(() => {
            this.setState({redirect:true})
      }, 4500);
    }
    


    render() {
        if (this.state.redirect) {
            return <Redirect push to={localStorage.getItem('sessionId') ? "/home" : "/application-landing"} />;
        }
        return (
            <div>
                <lottie-player
                     autoplay
                     loop
                     mode="normal"
                     src="humm_splash.json"
                     style={{width: '100%',height:'100vh',overflow:'hidden' }}
                ></lottie-player>
            </div>
        );
    }
}
