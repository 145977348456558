import axios from 'axios';
import config from '../config';
import {sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


export const manualLogout =async (formData) => {
    let url = config.hummBackendAppURL+'logout'

   return await axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        return err.response
    })


}