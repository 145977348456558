import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import HummAnimation from './component/dashboard/home/hummAnimation'
import Login from './component/auth/login/login';
import Home from './component/dashboard/home/home';
import PrivateRoute from './PrivateRoute.jsx';
import PublicRoute from './publicRoute.jsx';
import SimpleSlider from './component/dashboard/home/simpleSlider'
import { Provider } from 'react-redux'
import store from './store'
import ErrorBoundary from './component/errorBoundary/errorBoundary'
import ForgotPassword from './component/auth/login/forgotPassword'
import ForgotPasswordCode from './component/auth/login/forgotPasswordCode'
import Welcome from './component/dashboard/home/welcome';
import Layout from './component/layout/layout';

import Partner from './component/partner/partner';
import FavoritePartner from './component/partner/favoritePartner';
import ViewPartner from './component/partner/viewedPartner';
import MyAccount from './component/accountSetting/myAccount';

import Application from './component/application/application';
import QrCode from './component/miteck/qrCode'
import HummVerificationPage  from './component/miteck/hummVerificationPage'

import Wallet from './component/wallet/wallet';
import WalletContract from './component/wallet/walletContract';
import Addcard from './component/card/addCard';

import SessionExpiered from './component/sessionExpiered/sessionExpiered'
import ScrollToTop from './utils/scrollToTop';


import ApplyRedirect from './component/application/applyRedirect'
import QuoteCalculator from './component/quoteCalculator/quoteCalculator';
import QuoteCalculatorResults from './component/quoteCalculator/quoteCalculatorResults';
import NotFound from './component/notFound/notFound';
import PhoneVerification from './component/auth/register/phoneVerification';
import { MobileTermsPage } from './component/termsAndConditions/mobileTerms';
import applicationLanding from './component/application/applicationLanding';



export default () => {
 
    return (
      
      
             <Router>
                 <ScrollToTop />
                 <Provider store={store}>
                   <Switch>
                         <PublicRoute exact path='/' component={HummAnimation} />                    
                         <PublicRoute path='/application-landing' component={applicationLanding} />
                         <PublicRoute path="/login" component={Login} />
                         <PublicRoute path='/welcome' component={Welcome} />
                         <PublicRoute path='/forgot-password' component={ForgotPassword} />
                         <PublicRoute path='/forgot-password-code' component={ForgotPasswordCode} />
                         <PublicRoute path="/humm-verfication/:doc" component={HummVerificationPage} />
                         <PublicRoute path='/session-expired' component={SessionExpiered} /> 
                         <PublicRoute path="/page-not-found" component={NotFound} />                                       
                         <PublicRoute path='/apply' component={ApplyRedirect} />
                         <PublicRoute path="/mobile-terms" component={MobileTermsPage} />
                         
                         <Layout>
                         <ScrollToTop />
                         <PrivateRoute path='/home' component={Home} />
                         <PrivateRoute path='/shop' component={Partner} />
                         <PrivateRoute path='/favorite-partner' component={FavoritePartner} />
                         <PrivateRoute path='/wallet' component={Wallet} />
                         <PrivateRoute path='/wallet-contract-detail' component={WalletContract} />
                         <PrivateRoute path='/viewed-partner' component={ViewPartner} />
                         <PrivateRoute path='/my-account' component={MyAccount} />
                         <PrivateRoute path='/addcard' component={Addcard} />
                         <PrivateRoute path='/QrCode' component={QrCode} />
                         <PrivateRoute path='/application' component={Application} />
                         <PrivateRoute path='/apply' component={ApplyRedirect} />

                         <PrivateRoute path='/quote-calculator' component={QuoteCalculator} />  
                         <PrivateRoute path='/quote-calculator-results' component={QuoteCalculatorResults} /> 
                         <PrivateRoute path='/mobile-verification' component={PhoneVerification} />   
                         </Layout>
                         
                         <ErrorBoundary>
                         <Route path='/slider' component={SimpleSlider}/>
                         </ErrorBoundary>
                   </Switch>
                   </Provider>
               </Router>
             );
    }