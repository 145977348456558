import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


export const getAllPartners = (formData) => {

    let url = config.hummBackendAppURL+'partner/All-partners'
    return async (dispatch) => {
        let allPartnersResponse = [];
         await axios.post(url,  formData,{headers:sessionID()} )
            .then(response => {
                dispatch({
                    type: 'PARTNER_LIST',
                    value: response.data
                });
                allPartnersResponse = response;
                return allPartnersResponse;
            })
            .catch(async error => {
                await userLogs(url,formData,error);
                if(error.response){
        handlingSession(error.response.status);
        }
                allPartnersResponse = error.response;
                return allPartnersResponse;
            })
            return allPartnersResponse;
    }
};

