import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


export const searchFavoritePartner = (formData) => {

    let url = config.hummBackendAppURL+'favorite-partner/search-partner'
    return async (dispatch) => {
        let searchFavoritePartnerResponse=[];
        await axios.post(url,  formData,{headers:sessionID()} )
            .then(response => {
                dispatch({
                    type: 'FAVOURITE_SEARCH_PARTNER',
                    value: response.data
                });
                searchFavoritePartnerResponse = response;
                return searchFavoritePartnerResponse;
            })
            .catch(async error => {
                await userLogs(url,formData,error);
                if(error.response){
        handlingSession(error.response.status);
        }
                searchFavoritePartnerResponse = error.response;
                return searchFavoritePartnerResponse;
            })
            return searchFavoritePartnerResponse;
    }
};