export const authMiddleware = () => {

    if(localStorage.getItem('fromWhere')==='apply_now' || localStorage.getItem('webView') || localStorage.getItem('mobileRequired')){
        return true;
    }else{
        return (localStorage.getItem('token')==='' || localStorage.getItem('token')===null) ? false : true;
    }

    
    
};