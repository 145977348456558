import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'
import Cookies from 'universal-cookie';
import { frontEndLogs } from '../logs/frontEndLogs';

export const questions = () => {
    const cookie = new Cookies();
    let purchaseLinkDetails = {
        'Loan_Amount':cookie.get('loanAmount'),
        'Loan_Term':cookie.get('term'),
    }
    frontEndLogs('Retailer id in cookies : '+cookie.get('retailerId')+', local storage :'+localStorage.getItem('storeID') +', local storage quote retailer id :'+localStorage.getItem('RetailerIdFromQuoteTool'),false,200, 'getAllQuestions',purchaseLinkDetails);
    let amendRetailerIdIfExists = '';
    if(cookie.get('retailerId')) {
        amendRetailerIdIfExists = '?retailer_account__c='+cookie.get('retailerId');
    }else if(localStorage.getItem('storeID')){
        amendRetailerIdIfExists = '?retailer_account__c='+localStorage.getItem('storeID');
    }else if(localStorage.getItem('RetailerIdFromQuoteTool')){
        amendRetailerIdIfExists = '?retailer_account__c='+localStorage.getItem('RetailerIdFromQuoteTool');
    }

    if(amendRetailerIdIfExists && cookie.get('loanAmount') && cookie.get('term')){
        amendRetailerIdIfExists += '&loanAmt='+cookie.get('loanAmount')+'&term='+cookie.get('term');
    }
    let url = config.hummBackendAppURL+'application/get_all_application_questions' + amendRetailerIdIfExists;
    return async (dispatch) => {
        let resultResponse = [];
        await axios.get(url,{headers:sessionID()})
            .then(response => {
                dispatch({
                    type: 'APPLICATION_QUESTIONS',
                    value: response.data
                });
                resultResponse = response;
                return resultResponse;
            })
            .catch(async error => {
                await userLogs(url,'',error);
                if(error.response){
        handlingSession(error.response.status);
        }
                resultResponse = error.response;
                return resultResponse;
            })

            return resultResponse;
    }
};


